import React, { useEffect, useState } from 'react'
import './SystemDetailsSubHeader.css'
import { Link } from 'react-router-dom'
import { setInactiveSystemVersionButton, setNotificationVisibility, setSystemInactiveFullScreenModal, setUploadSystemVersionFullScreenModal } from '../../modules/actions/action';
import { connect } from 'react-redux';


const SystemDetailsSubHeader = (props) => {

  const [selectedEnv, setSelectedEnv] = useState("Prod");

  const handleEnvChange = (env) => {
    setSelectedEnv(env);
  };

  const handleInactiveButton = () => {
    console.log('Inactive button');
    props.openSystemInactiveModal();
  };

  const handleUploadButton = () => {
    console.log("Upload button Clicked!");
    props.openUploadSystemVersionModal();
  }

  return (
    <div className='system-detail-sub-header-container'>
      <div className='system-detail-sub-header'>
        <div className='system-detail-sub-header-heading'>
          OTA Update Uploads
        </div>
        <div className='sub-header-buttons'>
          <button className={`system-detail-sub-header-upload system-detail-sub-header-button`} onClick={handleUploadButton}>Upload</button>
          {/* <button className={`system-detail-sub-header-button ${props.inactiveSystemIcon ? 'system-detail-sub-header-inactive' : 'disabledInactiveIcon'}`} disabled={!props.inactiveSystemIcon} onClick={handleInactiveButton}>Inactive</button> */}
        </div>
      </div>
    </div>
  )
};

const mapStateToProps = state => {
  return {
    inactiveSystemIcon: state.inactiveSystemIcon.inactiveSystemVersionButton,
    showNotification: state.showNotification.showNotificatin,
    showUploadSystemVersionModal: state.uploadSystemVersionFullScreenModal.showUploadSystemVersionModal,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    enableSystemVersionInactiveButton: () => { dispatch(setInactiveSystemVersionButton(true)) },
    disableSystemVersionInactiveButton: () => { dispatch(setInactiveSystemVersionButton(false)) },
    openSystemInactiveModal: () => { dispatch(setSystemInactiveFullScreenModal(true)) },
		closeSystemInactiveModal: () => { dispatch(setSystemInactiveFullScreenModal(false)) },
    closeNotification: () => { dispatch(setNotificationVisibility(false)) },
    openNotification: () => { dispatch(setNotificationVisibility(true)) },
    openUploadSystemVersionModal: () => { dispatch(setUploadSystemVersionFullScreenModal(true)) },
    closeUploadSystemVersionModal: () => { dispatch(setUploadSystemVersionFullScreenModal(false)) },
  }
};

export default connect(mapStateToProps,mapDispatchToProps)(SystemDetailsSubHeader);
import React, { useState, useRef, useEffect } from 'react';
import './UploadSystemVersionFullScreenModal.css';
import cross_svg from '../../common/icons/cross.svg';
import { setUploadSystemVersionFullScreenModal } from '../../modules/actions/action';
import { setNotificationVisibility } from '../../modules/actions/action';
import { connect } from 'react-redux';
import UploadingProgress from '../UploadingProgress/UploadingProgress';
import UploadSystemVersionSuccessful from '../UploadSystemVersionSuccessful/UploadSystemVersionSuccessful';
import AWS, { CostExplorer } from 'aws-sdk';
import { getSystemVersionDetails } from '../../helper/SystemVersionDetailsHelper';
import { saveSoftwareVersionUploadHelper } from '../../helper/SystemVersionUploadHelper/SoftwareVersionUploadHelper';
import { showSystemVersionUploadNotification } from '../../modules/actions/action';
import { SOFTWARE_FILES, FIRMWARE_FILES, STMOS_FILES, IOMT_FILES } from './FILES_ENUM';
import { uploadFile } from '../../adapters/SystemVersionUploadAdapter/UploadFiles';

const S3_BUCKET = process.env.REACT_APP_S3_BUCKET;
const REGION = process.env.REACT_APP_AWS_REGION;

AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS,
    secretAccessKey: process.env.REACT_APP_AWS,
    region: REGION,
});
const s3 = new AWS.S3();
AWS.config.region = REGION;

const UploadSystemVersionFullScreenModal = (props) => {

    const [selectedSoftwareFiles, setSelectedSoftwareFiles] = useState([]);
    const [selectedFirmwareFiles, setSelectedFirmwareFiles] = useState([]);
    const [selectedStmosFiles, setSelectedStmosFiles] = useState([]);
    const [selectedIomtFiles, setSelectedIomtFiles] = useState([]);

    const softwareFileInputRef = useRef(null);
    const iomtFileInputRef = useRef(null);
    const firmwareFileInputRef = useRef(null);
    const stmosFileInputRef = useRef(null);

    const [softwareVersionUploadProgress, setSoftwareVersionUploadProgress] = useState(false);
    const [firmwareVersionUploadProgress, setFirmwareVersionUploadProgress] = useState(false);
    const [stmosVersionUploadProgress, setStmosVersionUploadProgress] = useState(false);
    const [iomtVersionUploadProgress, setIomtVersionUploadProgress] = useState(false);

    const [showSoftwareUploadProgress, setShowSoftwareUploadProgress] = useState(true);
    const [showFirmwareUploadProgress, setShowFirmwareUploadProgress] = useState(true);
    const [showIomtUploadProgress, setShowIomtUploadProgress] = useState(true);
    const [showStmosUploadProgress, setShowStmosUploadProgress] = useState(true);

    const [softwareFileUploadPercentage, setSoftwareFileUploadPercentage] = useState(0);
    const [firmwareFileUploadPercentage, setFirmwareFileUploadPercentage] = useState(0);
    const [stmosFileUploadPercentage, setStmosFileUploadPercentage] = useState(0);
    const [iomtFileUploadPercentage, setIomtFileUploadPercentage] = useState(0);

    const [softwareUploadMB, setSoftwareUploadMB] = useState(0);
    const [firmwareUploadMB, setFirmwareUploadMB] = useState(0);
    const [stmosUploadMB, setStmosUploadMB] = useState(0);
    const [iomtUploadMB, setIomtUploadMB] = useState(0);

    const [softwareUploadError, setSoftwareUploadError] = useState({});
    const [firmwareUploadError, setFirmwareUploadError] = useState({});
    const [stmosUploadError, setStmosUploadError] = useState({});
    const [iomtUploadError, setIomtUploadError] = useState({});

    const [softwareTotalMB, setSoftwareTotalMB] = useState(0);
    const [firmwareTotalMB, setFirmwareTotalMB] = useState(0);
    const [stmosTotalMB, setStmosTotalMB] = useState(0);
    const [iomtTotalMB, setIomtTotalMB] = useState(0);

    const [uploadMB, setUploadMB] = useState(0);
    const [totalMB, setTotalMB] = useState(0);
    const [uploadError, setUploadError] = useState(false);
    

    //Prod
    const [latestSystemVersion, setLatestSystemVersion] = useState({
        "softwareVersion": "0.0.0",
        "osVersion": "0.0.0",
    });
    const [lastSystemVersionDetails, setLastSystemVersionDetails] = useState(
        {
            "softwareVersion": "0.0.0",
            "osVersion": "0.0.0",
            "hmiUiVersion": "0.0.0",
            "hmiBackendVersion": "0.0.0",
            "fMasterVersion": "0.0.0",
            "fSlaveVersion": "0.0.0",
            "stmPyAppVersion": "0.0.0",
            "stmCAppVersion": "0.0.0",
            "stmOsVersion": "0.0.0",
            "iomtPyAppVersion": "0.0.0",
            "iomtJAppVersion": "0.0.0",
            "iomtOsVersion": "0.0.0",
            "description": "",
            "uploadDate": new Date(),
            "activeStatus": true,
            "TestEnv" : ''

        }
    );
    

    //Test
    const [latestSystemVersionTest, setLatestSystemVersionTest] = useState({
        "softwareVersion": "0.0.1.0",
        "osVersion": "0.0.1.0",
    });
    const [lastSystemVersionDetailsTest, setLastSystemVersionDetailsTest] = useState(
        {
            "softwareVersion": "0.0.1.0",
            "osVersion": "0.0.1.0",
            "hmiUiVersion": "0.0.1.0",
            "hmiBackendVersion": "0.0.1.0",
            "fMasterVersion": "0.0.1.0",
            "fSlaveVersion": "0.0.1.0",
            "stmPyAppVersion":  "0.0.1.0",
            "stmCAppVersion":  "0.0.1.0",
            "stmOsVersion":  "0.0.1.0",
            "iomtPyAppVersion":  "0.0.1.0",
            "iomtJAppVersion": "0.0.1.0",
            "iomtOsVersion":  "0.0.1.0",
            "description": "",
            "uploadDate": new Date(),
            "activeStatus": true,
            "TestEnv" : '',
            
        }
    );


    // Previous system version details-Prod
    const [updateSystemVersionDetails, setUpdateSystemVersionDetails] = useState({}); // Update system version details
    const [description, setDescription] = useState(""); // Description of the system version
    const [apiResponse, setApiResponse] = useState("");
    const systemVersionRef = useRef(true);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [fileCategory, setFileCategory] = useState('');
    
    //// Previous system version details-Test
    const [updateSystemVersionDetailsTest, setUpdateSystemVersionDetailsTest] = useState({}); // Update system version details



    const getLatestSystemVersion = () => getSystemVersionDetails().then((res) => {
        const systemVersionsArray = res.data;
    
        // Log API response to see structure
        console.log('API Response:', systemVersionsArray);
    
        // Prod Data
        if (systemVersionsArray.prod && systemVersionsArray.prod.length > 0) {
            const latestSystemVer = systemVersionsArray.prod[0];
            setLastSystemVersionDetails(latestSystemVer);
            
            setLatestSystemVersion({
                "softwareVersion": latestSystemVer.softwareVersion,
                "osVersion": latestSystemVer.osVersion
            });
            setUpdateSystemVersionDetails({
                "softwareVersion": latestSystemVer.softwareVersion,
                "osVersion": latestSystemVer.osVersion
            });
        } else {
            console.log("No 'prod' data available");
        }
    
        // Test Data - Only update if valid data exists
        // if(systemVersionsArray.test && systemVersionsArray.test.length ===0){
        //     setLastSystemVersionDetailsTest(prev => ({
        //         ...prev,
        //         "osVersion": lastSystemVersionDetails.osVersion,
        //         "stmPyAppVersion": lastSystemVersionDetails.stmPyAppVersion,
        //         "stmCAppVersion": lastSystemVersionDetails.stmCAppVersion,
        //         "stmOsVersion": lastSystemVersionDetails.stmOsVersion,
        //         "iomtPyAppVersion": lastSystemVersionDetails.iomtPyAppVersion,
        //         "iomtJAppVersion": lastSystemVersionDetails.iomtJAppVersion,
        //         "iomtOsVersion": lastSystemVersionDetails.iomtOsVersion,
        //     }));
        // }
        if (systemVersionsArray.test && systemVersionsArray.test.length > 0) {
            let latestSystemVerTest = systemVersionsArray.test[0];
            setLastSystemVersionDetailsTest(latestSystemVerTest);
            setLatestSystemVersionTest({
                "softwareVersion": latestSystemVerTest.softwareVersion,
                "osVersion": latestSystemVerTest.osVersion
            });
            setUpdateSystemVersionDetailsTest({
                "softwareVersion": latestSystemVerTest.softwareVersion,
                "osVersion": latestSystemVerTest.osVersion
            });
        } else {
            console.log("No 'test' data available, using default state values.");
        }
    })
    .catch((error) => {
        console.error("Error fetching system versions:", error);
    });
    
    useEffect(() => {
        getLatestSystemVersion();
    }, []);
    

    const handleDescriptionChange = (e) => {
        setDescription(e.target.value);
        console.log(e.target.value);
    };

    const handleFileChange = (event, fileCat) => {
        console.log("handleFileChange", event.target.files)
        const newSelectedFiles = Array.from(event.target.files);
        setSelectedFiles(newSelectedFiles);
        setFileCategory(fileCat);
    };
    
    useEffect(() => {
        if (selectedFiles.length) {
            switch (fileCategory) {
                case 'software':
                    if (selectedFiles[0]?.webkitRelativePath.split('/')[0] === 'software') {
                        setSelectedSoftwareFiles(selectedFiles);
                    } else {
                        setSoftwareUploadError((prev) => ({
                            ...prev,
                            wrongFileUploadError: true
                        }));
                    }
                    break;
                case 'firmware':
                    if (selectedFiles[0]?.webkitRelativePath.split('/')[0] === 'firmware') {
                        setSelectedFirmwareFiles(selectedFiles);
                    } else {
                        setFirmwareUploadError((prev) => ({
                            ...prev,
                            wrongFileUploadError: true
                        }));
                    }
                    break;
                case 'stmos':
                    if (selectedFiles[0]?.webkitRelativePath.split('/')[0] === 'stmOs') {
                        setSelectedStmosFiles(selectedFiles);
                    } else {
                        setStmosUploadError((prev) => ({
                            ...prev,
                            wrongFileUploadError: true
                        }));
                    }
                    break;
                case 'iomt':
                    if (selectedFiles[0]?.webkitRelativePath.split('/')[0] === 'iomtOs') {
                        setSelectedIomtFiles(selectedFiles);
                    } else {
                        setIomtUploadError((prev) => ({
                            ...prev,
                            wrongFileUploadError: true
                        }))
                    }
                    break;
                default:
                    break;
            }
        }
    }, [fileCategory, selectedFiles]);

    // Description of the system will be updated when the system description changes each time. 
    useEffect(() => {
        if(TestVersion==='Prod'){
            setLastSystemVersionDetails((prevState) => ({
                ...prevState,
                description: description
            }))
        }
        else if(TestVersion==='Test'){
            setLastSystemVersionDetailsTest((prevState) => ({
                ...prevState,
                description: description
            }))
        }
        

    }, [description]);

    // useEffect(() => {
    //     if (TestVersion === "Prod") {
    //         setLastSystemVersionDetails((prevState) => ({
    //             ...prevState,
    //             description: latestSystemVersion.description || prevState.description
    //         }));
    //     } else if (TestVersion === "Test") {
    //         setLastSystemVersionDetailsTest((prevState) => ({
    //             ...prevState,
    //             description: latestSystemVersionTest.description || prevState.description
    //         }));
    //     }
    // }, [latestSystemVersion.description, latestSystemVersionTest.description, TestVersion]);
    

    const handleUploadButtonClass = () => {
        if (TestVersion === 'Prod') {
            return (updateSystemVersionDetails.softwareVersion != lastSystemVersionDetails.softwareVersion || 
                    updateSystemVersionDetails.osVersion != lastSystemVersionDetails.osVersion) ? false : true;
        } else if (TestVersion === 'Test') {
            return (updateSystemVersionDetailsTest.softwareVersion != lastSystemVersionDetailsTest.softwareVersion || 
                updateSystemVersionDetailsTest.osVersion != lastSystemVersionDetailsTest.osVersion) ? false : true;
            
        }
    };
    

    useEffect(() => {
        handleUploadButtonClass();
    }, [updateSystemVersionDetails]);

    const handleSystemVersionUpload = async () => {
        if(TestVersion==='Prod'){
            console.log("Last System Version Details of Prod: ", lastSystemVersionDetails);
            let versionData = lastSystemVersionDetails;
            
            await saveSoftwareVersionUploadHelper(versionData)
                .then((response) => {
                    console.log("RESPONSE OF UPLOAD: ", response);
                    // if(response.success === false){
                    setApiResponse(response);
                    props.showSystemVersionUploadNotification();
                    props.closeModal();
                    // window.location.reload(false);
                    // }
                }).catch((error) => {
                    console.log("API EROOR RES", error);
                });
    
            setUpdateSystemVersionDetails({
                "softwareVersion": versionData.softwareVersion,
                "osVersion": versionData.osVersion
            });
        }
        else if (TestVersion === 'Test') {
            
            console.log("Last System Version Details of Test: ", lastSystemVersionDetailsTest);
            let versionData = lastSystemVersionDetailsTest;


            await saveSoftwareVersionUploadHelper(versionData)
                .then((response) => {
                    console.log("RESPONSE OF UPLOAD: ", response);
                    // if(response.success === false){
                    setApiResponse(response);
                    props.showSystemVersionUploadNotification();
                    props.closeModal();
                    // window.location.reload(false);
                    // }
                }).catch((error) => {
                    console.log("API EROOR RES", error);
                });
    
            setUpdateSystemVersionDetailsTest({
                "softwareVersion": versionData.softwareVersion,
                "osVersion": versionData.osVersion
            });


        }
        
    };

    const uploadSoftwareFileToAWSFolder = async (filePath, folderName, allFiles, index) => {
        console.log("FilePath", filePath);
        
        if(TestVersion==='Prod'){
            console.log('Prod Software Uploading')
            const softwareVersionFolder = parseInt(latestSystemVersion.softwareVersion.toString().split('.')[1]) + 1;
            try {
                // let filesToUpload = [filePath];
                uploadFile(filePath, ('software/' + softwareVersionFolder), TestVersion , (progress) => {

                    const { loaded, total } = progress;
                    const loadedMB = Math.round((loaded / (1024 * 1024))).toFixed(0);
                    const totalMB = Math.round((total / (1024 * 1024))).toFixed(0);
                    const uploadProgress = Math.round((loaded / total) * 100);
                    console.log(`Upload progress: ${uploadProgress}%`);
                    setSoftwareFileUploadPercentage(uploadProgress);
                    setSoftwareUploadMB(loadedMB);
                    setSoftwareTotalMB(totalMB);
                    if ((uploadProgress === 100) && (index + 1 === allFiles)) {
                        setShowSoftwareUploadProgress(false);
                    }
                });
            } catch (error) {
                console.log(`Error uploading Prod file: ${error.message}`);
            };
        }

        else if(TestVersion==='Test'){
            console.log('Test Software Uploading')
            
            let folderName = filePath.name.split('_')[1];
            let softwareVersionFolderTest = folderName.split('.')[3];

            const currSoftwareDirectory = parseInt(latestSystemVersion.softwareVersion.toString().split('.')[1]);
            
            try {
                // let filesToUpload = [filePath];
                uploadFile(filePath, ('software/' + currSoftwareDirectory + '/'+ softwareVersionFolderTest) , TestVersion, (progress) => {
                    const { loaded, total } = progress;
                    const loadedMB = Math.round((loaded / (1024 * 1024))).toFixed(0);
                    const totalMB = Math.round((total / (1024 * 1024))).toFixed(0);
                    const uploadProgress = Math.round((loaded / total) * 100);
                    console.log(`Upload progress: ${uploadProgress}%`);
                    setSoftwareFileUploadPercentage(uploadProgress);
                    setSoftwareUploadMB(loadedMB);
                    setSoftwareTotalMB(totalMB);
                    if ((uploadProgress === 100) && (index + 1 === allFiles)) {
                        setShowSoftwareUploadProgress(false);
                    }

                });
            } catch (error) {
                console.log(`Error uploading Test file: ${error.message}`);
            };



        }

        
        //const fileKey = `software/${softwareVersionFolder}/${filePath.name}`;
        // const params = {
        //     Bucket: S3_BUCKET,
        //     Key: fileKey,
        //     Body: filePath,
        // };

        // const uploadRequest = s3.upload(params);
        // uploadRequest.on('httpUploadProgress', (progress) => {
        //     const { loaded, total } = progress;
        //     const loadedMB = Math.round((loaded / (1024 * 1024))).toFixed(0);
        //     const totalMB = Math.round((total / (1024 * 1024))).toFixed(0);

        //     const uploadProgress = Math.round((loaded / total) * 100);
        //     setSoftwareFileUploadPercentage(uploadProgress);
        //     setSoftwareUploadMB(loadedMB);
        //     setSoftwareTotalMB(totalMB);
        //     if ((uploadProgress === 100) && (index + 1 === allFiles)) {
        //         setShowSoftwareUploadProgress(false);
        //     }
        // });

        // uploadRequest.send((err, data) => {
        //     if (err) {
        //         console.log(`Error uploading file`, err);
        //         reject(err);
        //     } else {
        //         console.log(`${filePath.name} uploaded successfully`, data);
        //         resolve(data);
        //     }
        // })
    };
    const uploadFirmwareFileToAWSFolder = async (filePath, folderName, allFiles, index) => {

        if(TestVersion==='Prod'){
        const firmwareVersionFolder = parseInt(latestSystemVersion.softwareVersion.toString().split('.')[0]) + 1;
        try {
            uploadFile(filePath, ('firmware/' + firmwareVersionFolder), TestVersion , (progress) => {
                const { loaded, total } = progress;
                console.log("loaded", loaded, "total", total);
                const loadedMB = Math.round((loaded / (1024 * 1024))).toFixed(0);
                const totalMB = Math.round((total / (1024 * 1024))).toFixed(0);
                const uploadProgress = Math.round((loaded / total) * 100);
                console.log(`Upload progress: ${uploadProgress}%`);
                setFirmwareFileUploadPercentage(uploadProgress);
                setFirmwareUploadMB(loadedMB);
                setFirmwareTotalMB(totalMB);
                if ((uploadProgress === 100) && (index + 1 === allFiles)) {
                    setShowFirmwareUploadProgress(false);
                }
            });
        } catch (error) {
            console.log(`Error uploading file: ${error.message}`);
        };
    
    }
        else if(TestVersion==='Test'){

            console.log('Test Firmware Uploading')
            console.log(filePath);
           
            let folderName = filePath.name.split('_')[1];
            let firmwareVersionFolderTest = folderName.split('.')[3];



            // if(arrTest[0]===arrProd[0] && arrTest[1]===arrProd[1]){
            //     firmwareVersionFolderTest = parseInt(latestSystemVersionTest.softwareVersion.toString().split('.')[3]) + 1;
            // }else{
            //     firmwareVersionFolderTest=1;
            // }
            

            const currSoftwareDirectory = parseInt(latestSystemVersion.softwareVersion.toString().split('.')[0]);
          
            try {
                // let filesToUpload = [filePath];
                uploadFile(filePath, ('firmware/' + currSoftwareDirectory + '/'+ firmwareVersionFolderTest), TestVersion , (progress) => {
                    const { loaded, total } = progress;
                    const loadedMB = Math.round((loaded / (1024 * 1024))).toFixed(0);
                    const totalMB = Math.round((total / (1024 * 1024))).toFixed(0);
                    const uploadProgress = Math.round((loaded / total) * 100);
                    console.log(`Upload progress: ${uploadProgress}%`);
                    setFirmwareFileUploadPercentage(uploadProgress);
                    setFirmwareUploadMB(loadedMB);
                    setFirmwareTotalMB(totalMB);
                    if ((uploadProgress === 100) && (index + 1 === allFiles)) {
                        setShowFirmwareUploadProgress(false);
                    }

                });
            } catch (error) {
                console.log(`Error uploading Test file: ${error.message}`);
            };


    }


        // return new Promise((resolve, reject) => {
        //     try {
        //         const fileKey = `firmware/${firmwareVersionFolder}/${filePath.name}`;

        //         const params = {
        //             Bucket: S3_BUCKET,
        //             Key: fileKey,
        //             Body: filePath,
        //         };

        //         const uploadRequest = s3.upload(params);
        //         uploadRequest.on('httpUploadProgress', (progress) => {
        //             const { loaded, total } = progress;
        //             const loadedMB = Math.round((loaded / (1024 * 1024))).toFixed(0);
        //             const totalMB = Math.round((total / (1024 * 1024))).toFixed(0);

        //             const uploadProgress = Math.round((loaded / total) * 100);
        //             setFirmwareFileUploadPercentage(uploadProgress);
        //             setFirmwareUploadMB(loadedMB);
        //             setFirmwareTotalMB(totalMB);
        //             if ((uploadProgress === 100) && (index + 1 === allFiles)) {
        //                 setShowFirmwareUploadProgress(false);
        //             }
        //         });

        //         uploadRequest.send((err, data) => {
        //             if (err) {
        //                 console.log(`Error uploading file`, err);
        //                 reject(err);
        //             } else {
        //                 console.log(`File uploaded successfully`);
        //                 resolve(data)
        //             }
        //         })
        //     } catch (error) {
        //         console.log(`Error uploading file: ${error.message}`);
        //     }
        // })
    };
    const uploadStmosFileToAWSFolder = async (filePath, folderName, allFiles, index) => {

        if(TestVersion==='Prod'){
            const stmosVersionUpload = parseInt(latestSystemVersion.osVersion.toString().split('.')[1]) + 1;
            try {
                uploadFile(filePath, ('stmOs/' + stmosVersionUpload),TestVersion, (progress) => {
                    const { loaded, total } = progress;
                    const loadedMB = Math.round((loaded / (1024 * 1024))).toFixed(0);
                    const totalMB = Math.round((total / (1024 * 1024))).toFixed(0);
                    const uploadProgress = Math.round((loaded / total) * 100);
                    console.log(`Upload progress: ${uploadProgress}%`);
                    setStmosFileUploadPercentage(uploadProgress);
                    setStmosUploadMB(loadedMB);
                    setStmosTotalMB(totalMB);
                    if ((uploadProgress === 100) && (index + 1 === allFiles)) {
                        setShowStmosUploadProgress(false);
                    }
                });
            } catch (error) {
                console.log(`Error uploading file: ${error.message}`);
            };
        }

        else if(TestVersion==='Test'){
            console.log('Test Software Uploading');
            
            let folderName = filePath.name.split('_')[1];
            let stmosVersionFolderTest = folderName.split('.')[3]

            const stmosVersionUpload = parseInt(latestSystemVersion.osVersion.toString().split('.')[1]);
            try {
                uploadFile(filePath, ('stmOs/' + stmosVersionUpload + '/' + stmosVersionFolderTest), TestVersion ,(progress) => {
                    const { loaded, total } = progress;
                    const loadedMB = Math.round((loaded / (1024 * 1024))).toFixed(0);
                    const totalMB = Math.round((total / (1024 * 1024))).toFixed(0);
                    const uploadProgress = Math.round((loaded / total) * 100);
                    console.log(`Upload progress: ${uploadProgress}%`);
                    setStmosFileUploadPercentage(uploadProgress);
                    setStmosUploadMB(loadedMB);
                    setStmosTotalMB(totalMB);
                    if ((uploadProgress === 100) && (index + 1 === allFiles)) {
                        setShowStmosUploadProgress(false);
                    }
                });
            } catch (error) {
                console.log(`Error uploading file: ${error.message}`);
            };

        }
        
        // return new Promise((resolve, reject) => {
        //     try {
        //         const fileKey = `stmOs/${stmosVersionUpload}/${filePath.name}`;

        //         const params = {
        //             Bucket: S3_BUCKET,
        //             Key: fileKey,
        //             Body: filePath,
        //         };

        //         const fileType = folderName.split('/')[0];
        //         const uploadRequest = s3.upload(params, {
        //             onProgress: (progress) => {
        //                 console.log("progress:", progress.loaded, "/", progress.total);
        //             }
        //         });
        //         uploadRequest.on('httpUploadProgress', (progress) => {
        //             const { loaded, total } = progress;
        //             const loadedMB = Math.round((loaded / (1024 * 1024))).toFixed(0);
        //             const totalMB = Math.round((total / (1024 * 1024))).toFixed(0);
        //             const uploadProgress = Math.round((loaded / total) * 100);
        //             setStmosFileUploadPercentage(uploadProgress);
        //             setStmosUploadMB(loadedMB);
        //             setStmosTotalMB(totalMB);
        //             if ((uploadProgress === 100) && (index + 1 === allFiles)) {
        //                 setShowStmosUploadProgress(false);
        //             }
        //         });

        //         uploadRequest.send((err, data) => {
        //             if (err) {
        //                 console.log(`Error uploading file`, err);
        //                 reject(err);
        //             } else {
        //                 console.log(`File uploaded successfully`);
        //                 resolve(data);
        //             }
        //         })

        //     } catch (error) {
        //         console.log(`Error uploading file: ${error.message}`);
        //     }
        // })
    };
    const uploadIomtFileToAWSFolder = async (filePath, folderName, allFiles, index) => {
        
        if(TestVersion==='Prod'){

            const iomtVersionFolder = parseInt(latestSystemVersion.osVersion.toString().split('.')[0]) + 1;

            try {
                uploadFile(filePath, ('iomtOs/' + iomtVersionFolder),TestVersion, (progress) => {
                    const { loaded, total } = progress;
                    const loadedMB = Math.round((loaded / (1024 * 1024))).toFixed(0);
                    const totalMB = Math.round((total / (1024 * 1024))).toFixed(0);
                    const uploadProgress = Math.round((loaded / total) * 100);
                    console.log(`Upload progress: ${uploadProgress}%`);
                    setIomtFileUploadPercentage(uploadProgress);
                    setIomtUploadMB(loadedMB);
                    setIomtTotalMB(totalMB);
                    if ((uploadProgress === 100) && (index + 1 === allFiles)) {
                        setShowIomtUploadProgress(false);
                    }
                });
            } catch (error) {
                console.log(`Error uploading file: ${error.message}`);
            };
        }
        else if(TestVersion==='Test'){
            console.log('Test Software Uploading')
            
            let folderName = filePath.name.split('_')[1];
            let iomtVersionFolderTest = folderName.split('.')[3];
            
            const iomtVersionFolder = parseInt(latestSystemVersion.osVersion.toString().split('.')[0]);

            try {
                uploadFile(filePath, ('iomtOs/' + iomtVersionFolder + '/'+ iomtVersionFolderTest), TestVersion , (progress) => {
                    const { loaded, total } = progress;
                    const loadedMB = Math.round((loaded / (1024 * 1024))).toFixed(0);
                    const totalMB = Math.round((total / (1024 * 1024))).toFixed(0);
                    const uploadProgress = Math.round((loaded / total) * 100);
                    console.log(`Upload progress: ${uploadProgress}%`);
                    setIomtFileUploadPercentage(uploadProgress);
                    setIomtUploadMB(loadedMB);
                    setIomtTotalMB(totalMB);
                    if ((uploadProgress === 100) && (index + 1 === allFiles)) {
                        setShowIomtUploadProgress(false);
                    }
                });
            } catch (error) {
                console.log(`Error uploading file: ${error.message}`);
            };
        }
        
        // return new Promise((resolve, reject) => {
        //     try {
        //         const fileKey = `iomtOs/${iomtVersionFolder}/${filePath.name}`;

        //         const params = {
        //             Bucket: S3_BUCKET,
        //             Key: fileKey,
        //             Body: filePath,
        //         };

        //         const fileType = folderName.split('/')[0];
        //         const uploadRequest = s3.upload(params);
        //         uploadRequest.on('httpUploadProgress', (progress) => {
        //             const { loaded, total } = progress;
        //             const loadedMB = Math.round((loaded / (1024 * 1024))).toFixed(0);
        //             const totalMB = Math.round((total / (1024 * 1024))).toFixed(0);
        //             const uploadProgress = Math.round((loaded / total) * 100);
        //             setIomtFileUploadPercentage(uploadProgress);
        //             setIomtUploadMB(loadedMB);
        //             setIomtTotalMB(totalMB);
        //             if ((uploadProgress === 100) && (index + 1 === allFiles)) {
        //                 setShowIomtUploadProgress(false);
        //             }
        //         });

        //         uploadRequest.send((err, data) => {
        //             if (err) {
        //                 console.log(`Error uploading file`, err);
        //                 reject(err);
        //             } else {
        //                 console.log(`File uploaded successfully`);
        //                 resolve(data);
        //             }
        //         })

        //     } catch (error) {
        //         console.log(`Error uploading file: ${error.message}`);
        //     }
        // })
    };


    // Use Effects to call AWSUpload function on selected categories file changes
    //Prod
    const [updateSystemVersion, setUpdateSystemVersion] = useState({
        updateSoftwareVersion: false,
        updateFirmwareVersion: false,
        updateStmosVersion: false,
        updateIomtVersion: false
    });

    const [isSoftwareUpdatedSystemVersion, setIsSoftwareUpdatedSystemVersion] = useState(false);
    const [isFirmwareUpdatedSystemVersion, setIsFirmwareUpdatedSystemVersion] = useState(false);
    const [isStmostUpdatedSystemVersion, setIsStmostUpdatedSystemVersion] = useState(false);
    const [isImotUpdatedSystemVersion, setIsImotUpdatedSystemVersion] = useState(false);
    

    //Test
    const [updateSystemVersionTest, setUpdateSystemVersionTest] = useState({
        updateSoftwareVersionTest: false,
        updateOsVersionTest: false
    });
    
    const [isupdateSoftwareVersionTest, setIsupdateSoftwareVersionTest] = useState(false);
    const [isupdateOsVersionTest, setIsupdateOsVersionTest] = useState(false);


    // Version checks before upload

    const shouldUploadFile = (file, lastFileVersion) => {
        console.log("shouldUploadFile >>> ", file, "lastfile >> ", lastFileVersion);
        return new Promise((resolve, reject) => {

            const fileVersion = file.name.split('_')[1];

            let currentMajor = +fileVersion.split('.')[0];
            let currentMinor = +fileVersion.split('.')[1];
            let currentProduction = +fileVersion.split('.')[2];

            let lastMajor = +lastFileVersion.split('.')[0];
            let lastMinor = +lastFileVersion.split('.')[1];
            let lastProduction = +lastFileVersion.split('.')[2];

            if (currentMajor > lastMajor || (currentMajor === lastMajor && currentMinor > lastMinor) || (currentMajor === lastMajor && currentMinor === lastMinor && currentProduction > lastProduction)) {
                console.log(`Ok! so you have new version :) , ${file.name}`);
                resolve(true);
            } else {
                console.log(`YOu are uploading the older version of =:^<^>^:= , ${file.name}`);
                resolve(false);
            }

            reject(true);

        });
    };

    const shouldUploadFileTest = (file, lastFileVersionProd , lastFileVersionTest) => {
        return new Promise((resolve, reject) => {
            

            const fileVersion = file.name.split('_')[1];


            let currentMajorTest = +fileVersion.split('.')[0];
            console.log('currentMajorTest:', currentMajorTest);

            let currentMinorTest = +fileVersion.split('.')[1];
            console.log('currentMinorTest:', currentMinorTest);

            let currentTestEnv = +fileVersion.split('.')[2];
            console.log('currentTestEnv:', currentTestEnv);

            let currTestversion = +fileVersion.split('.')[3];
            console.log('currTestversion:', currTestversion);

            let lastMajorTest = +lastFileVersionTest.split('.')[0];
            console.log('lastMajorTest:', lastMajorTest);

            let lastMinorTest = +lastFileVersionTest.split('.')[1];
            console.log('lastMinorTest:', lastMinorTest);

            let lastTestVersion = +lastFileVersionTest.split('.')[3];
            console.log('lastTestVersion:', lastTestVersion);

            let lastMajorProd = +lastFileVersionProd.split('.')[0];
            console.log('lastMajorProd:', lastMajorProd);

            let lastMinorProd = +lastFileVersionProd.split('.')[1];
            console.log('lastMinorProd:', lastMinorProd);


            if(lastMajorProd===currentMajorTest && lastMinorProd===currentMinorTest && currentTestEnv===1){
                   
                if(lastMajorTest===currentMajorTest && lastMinorTest===currentMinorTest && lastTestVersion < currTestversion){
                    console.log(`Ok! so you have new version :) , ${file.name} ,`);
                    resolve(true);
                }
                else{
                    if(currTestversion===1){
                        console.log(`Ok! so you are starting a new test version series  :) , ${file.name}`);
                        resolve(true);
                    }else{
                        console.log(`YOu are uploading the older version of =:^<^>^:= , ${file.name}`);
                        resolve(false);
                    }
                }

   
            }else{
                console.log(`${file.name} , Wrong test Version File`);
                resolve(false);
            }
            

            // if (currentMajor > lastMajor || (currentMajor === lastMajor && currentMinor > lastMinor) || (currentMajor === lastMajor && currentMinor === lastMinor && currentProduction > lastProduction)) {
            //     console.log(`Ok! so you have new version :) , ${file.name}`);
            //     resolve(true);
            // } else {
            //     console.log(`YOu are uploading the older version of =:^<^>^:= , ${file.name}`);
            //     resolve(false);
            // }

            // reject(true);

        });
    };

    const sortFileArray = (filesData) => {
        const files = filesData;
        console.log("sortFileArray", files);
        const fileNames = [];
        for (const i of files) {
            fileNames.push(i.name);
        }
        const sortedFileNames = fileNames.sort();
        const sortedFilesData = [];
        for (const name of sortedFileNames) {
            for (const i of files) {
                if (name === i.name) {
                    sortedFilesData.push(i);
                }
            }
        }
        return sortedFilesData;
    };
    

    
    const [isNewSeries, setisNewSeries] = useState(false); 

    useEffect(()=>
    {   

        if(TestVersion==='Test'){

               
            if (updateSystemVersionTest.updateSoftwareVersionTest=== true) {
                let arrTestSoftware= latestSystemVersionTest.softwareVersion.toString().split('.');
                let arrProdSoftware = latestSystemVersion.softwareVersion.toString().split('.');
                let arrTestOS= latestSystemVersionTest.osVersion.toString().split('.');
                let arrProdOS = latestSystemVersion.osVersion.toString().split('.');

                
               
                if (isupdateSoftwareVersionTest === false) {
                    
                    if( (arrTestSoftware[0]===arrProdSoftware[0] && arrTestSoftware[1]===arrProdSoftware[1]) &&  (arrTestOS[0]===arrProdOS[0] && arrTestOS[1]===arrProdOS[1])){
                        console.log('Update the earlier version');
                          arrTestSoftware[3]= parseInt(arrTestSoftware[3]) + 1;
                    }
                    else{
                      console.log('Starting a new test series');
                      arrTestSoftware[0]=arrProdSoftware[0];
                      arrTestSoftware[1]=arrProdSoftware[1];
                      arrTestSoftware[3]='1';
                      setisNewSeries(true); // Set the indicator

                    }
                    setIsupdateSoftwareVersionTest(true);
                }
                const newSystemVersion = arrTestSoftware.join('.');
                setLatestSystemVersionTest((prevState) => ({
                    ...prevState,
                    softwareVersion: newSystemVersion
                }));
                setLastSystemVersionDetailsTest((prevState) => ({
                    ...prevState,
                    softwareVersion: newSystemVersion,
                }));
                
            }

            if(updateSystemVersionTest.updateOsVersionTest=== true) {
                let arrTestSoftware= latestSystemVersionTest.softwareVersion.toString().split('.');
                let arrProdSoftware = latestSystemVersion.softwareVersion.toString().split('.');
                let arrTestOS= latestSystemVersionTest.osVersion.toString().split('.');
                let arrProdOS = latestSystemVersion.osVersion.toString().split('.');
                
                if (isupdateOsVersionTest === false) {
                    if (isNewSeries) {
                        // If a new series started for the software, start a new series for OS as well
                        console.log('Starting a new test series');
                        arrTestOS[0] = arrProdOS[0];
                        arrTestOS[1] = arrProdOS[1];
                        arrTestOS[3] = '1';
                    } else if (
                        (arrTestOS[0] === arrProdOS[0] && arrTestOS[1] === arrProdOS[1]) &&
                        (arrTestSoftware[0] === arrProdSoftware[0] && arrTestSoftware[1] === arrProdSoftware[1])
                    ) {
                        console.log('Update the earlier version');
                        arrTestOS[3] = parseInt(arrTestOS[3]) + 1;
                    } 
                    setIsupdateOsVersionTest(true);
                }
                const newSystemVersion = arrTestOS.join('.');
                setLatestSystemVersionTest((prevState) => ({
                    ...prevState,
                    osVersion: newSystemVersion
                }));
                setLastSystemVersionDetailsTest((prevState) => ({
                    ...prevState,
                    osVersion: newSystemVersion,
                }));
            }
        }

    }, [updateSystemVersionTest])

    useEffect(() => {
        // console.log("updateSystemVersin called!", updateSystemVersion);
        if(TestVersion==='Prod'){
            if (updateSystemVersion.updateSoftwareVersion === true) {
                let arr = latestSystemVersion.softwareVersion.toString().split('.');
                if (isSoftwareUpdatedSystemVersion === false) {
                    console.log("System Version updated by software!");
                    arr[1] = parseInt(arr[1]) + 1;
                    setIsSoftwareUpdatedSystemVersion(true);
                }
                const newSystemVersion = arr.join('.');
                setLatestSystemVersion((prevState) => ({
                    ...prevState,
                    softwareVersion: newSystemVersion
                }));
                setLastSystemVersionDetails((prevState) => ({
                    ...prevState,
                    softwareVersion: newSystemVersion,
                }));
                // console.log(arr);
                // console.log(latestSystemVersion);
            }
    
            if (updateSystemVersion.updateFirmwareVersion === true) {
                let arr = latestSystemVersion.softwareVersion.toString().split('.');
    
                if (isFirmwareUpdatedSystemVersion === false) {
                    console.log("System Version updated by Firmware!");
                    arr[0] = parseInt(arr[0]) + 1;
                    setIsFirmwareUpdatedSystemVersion(true);
                }
                // if (parseInt(latestSystemVersion.toString().split('.')[2]) === parseInt(arr[2])) {
                //     // console.log("System Version updated by Firmware!");
                //     arr[2] = parseInt(arr[2]) + 1;
                // }
    
                const newSystemVersion = arr.join('.');
                setLatestSystemVersion((prevState) => ({
                    ...prevState,
                    softwareVersion: newSystemVersion
                }));
                setLastSystemVersionDetails((prevState) => ({
                    ...prevState,
                    softwareVersion: newSystemVersion,
                }));
                // console.log(latestSystemVersion);
            }
    
            if (updateSystemVersion.updateStmosVersion === true) {
                let arr = latestSystemVersion.osVersion.toString().split('.');
                console.log("Inside UpdateSystemVersion-stmos", arr);
    
                if (isStmostUpdatedSystemVersion === false) {
                    console.log("System Version updated by Stmos!");
                    arr[1] = parseInt(arr[1]) + 1;
                    setIsStmostUpdatedSystemVersion(true);
                }
    
                const newSystemVersion = arr.join('.');
                console.log(newSystemVersion);
                setLatestSystemVersion((prevState) => ({
                    ...prevState,
                    osVersion: newSystemVersion
                }));
                setLastSystemVersionDetails((prevState) => ({
                    ...prevState,
                    osVersion: newSystemVersion,
                }));
            }
    
            if (updateSystemVersion.updateIomtVersion === true) {
                let arr = latestSystemVersion.osVersion.toString().split('.');
                console.log("Inside UpdateSystemVersion-iomt", arr);
                if (isImotUpdatedSystemVersion === false) {
                    console.log("System Version updated by IOmt!");
                    arr[0] = parseInt(arr[0]) + 1;
                    setIsImotUpdatedSystemVersion(true);
                }
    
                const newSystemVersion = arr.join('.');
                setLatestSystemVersion((prevState) => ({
                    ...prevState,
                    osVersion: newSystemVersion
                }));
                setLastSystemVersionDetails((prevState) => ({
                    ...prevState,
                    osVersion: newSystemVersion,
                }));
            }
        }
        
    }, [updateSystemVersion]);

    const uploadSelectedSoftwareFiles = async () => {
        console.log('SOFTWARE_FILES', SOFTWARE_FILES, "TestVersion >>", TestVersion);
        if (selectedSoftwareFiles.length < SOFTWARE_FILES['MIN_NUM_FILES_REQUIRED']) {
            alert(`Minimum number of files required is ${SOFTWARE_FILES['MIN_NUM_FILES_REQUIRED']}`);
            return;
        }
        const sortedFiles = sortFileArray(selectedSoftwareFiles);
        console.log(sortedFiles);
        let FeFileEligibleForUpload = false;
        let BeFileEligibleForUpload = false;
        let eligibleFiles = [];
        try {
            for (let i = 0; i < sortedFiles.length; i++) {
                const selectedSoftwareFile = sortedFiles[i];
                console.log(selectedSoftwareFile);
                if (selectedSoftwareFile.name.split('_')[0] === 'BE') {
                    console.log('Backend File');
                    const { hmiBackendVersion: hmiBackendVersionProd  } = lastSystemVersionDetails;
                    console.log(hmiBackendVersionProd);
                    const { hmiBackendVersion : hmiBackendVersionTest } = lastSystemVersionDetailsTest;
                    console.log(hmiBackendVersionTest);

                    if(TestVersion ==='Prod') {
                        BeFileEligibleForUpload = await shouldUploadFile(selectedSoftwareFile, hmiBackendVersionProd)
                    }
                    else if(TestVersion==='Test'){BeFileEligibleForUpload = await shouldUploadFileTest(selectedSoftwareFile, hmiBackendVersionProd , hmiBackendVersionTest);}
                    
                    console.log(`Running the shouldUploadFile for ${selectedSoftwareFile.name} in software`);
                    eligibleFiles.push(selectedSoftwareFile);

                } else if (selectedSoftwareFile.name.split('_')[0] === 'UI') {
                    console.log('UI File');
                    const { hmiUiVersion: hmiUiVersionProd  } = lastSystemVersionDetails;
                    const { hmiUiVersion : hmiUiVersionTest } = lastSystemVersionDetailsTest;
                    if(TestVersion==='Prod'){BeFileEligibleForUpload = await shouldUploadFile(selectedSoftwareFile, hmiUiVersionProd);}
                    else if(TestVersion==='Test'){BeFileEligibleForUpload = await shouldUploadFileTest(selectedSoftwareFile, hmiUiVersionProd , hmiUiVersionTest);}
                    console.log(`Running the shouldUploadFile for ${selectedSoftwareFile.name} in software }}`);
                    eligibleFiles.push(selectedSoftwareFile);
                }
            }
            
            console.log(eligibleFiles);
            if (eligibleFiles?.length >= 2 && (FeFileEligibleForUpload || BeFileEligibleForUpload)) {
                for (let i = 0; i < eligibleFiles.length; i++) {
                    const eligibleFile = eligibleFiles[i];
                    console.log("eligibleFile", eligibleFile);
                    await uploadSoftwareFileToAWSFolder(eligibleFile, eligibleFile?.webkitRelativePath, eligibleFiles.length, i);
                    if (TestVersion === 'Prod') {
                        setUpdateSystemVersion({ ...updateSystemVersion, updateSoftwareVersion: true });
                        if (eligibleFile.name.split('_')[0] === 'BE') {
                            const versionNumber = eligibleFile.name.split("_")[1].substring(0, eligibleFile.name.split("_")[1].lastIndexOf("."));
                            console.log("This is the changed file versionNumber", versionNumber);
                            setLastSystemVersionDetails((prevState) => ({
                                ...prevState,
                                hmiBackendVersion: versionNumber
                            }));
                        } else if (eligibleFile.name.split('_')[0] === 'UI') {
                            const versionNumber = eligibleFile.name.split("_")[1].substring(0, eligibleFile.name.split("_")[1].lastIndexOf("."));
                            console.log(`THis is the ${versionNumber}`);
                            setLastSystemVersionDetails((prevState) => ({
                                ...prevState,
                                hmiUiVersion: versionNumber
                            }))
                        }
                    }
                    else if(TestVersion==='Test'){
                        setUpdateSystemVersionTest({ ...updateSystemVersionTest, updateSoftwareVersionTest: true});
                        if (eligibleFile.name.split('_')[0] === 'BE') {
                            const versionNumber = eligibleFile.name.split("_")[1].substring(0, eligibleFile.name.split("_")[1].lastIndexOf("."));
                            console.log("This is the changed file versionNumber", versionNumber);
                            setLastSystemVersionDetailsTest((prevState) => ({
                                ...prevState,
                                hmiBackendVersion: versionNumber
                            }));
                        } else if (eligibleFile.name.split('_')[0] === 'UI') {
                            const versionNumber = eligibleFile.name.split("_")[1].substring(0, eligibleFile.name.split("_")[1].lastIndexOf("."));
                            console.log(`THis is the ${versionNumber}`);
                            setLastSystemVersionDetailsTest((prevState) => ({
                                ...prevState,
                                hmiUiVersion: versionNumber
                            }))
                        }


                    }
                    
                }
            }
            else {
                alert('No eligible file to upload, Please try again with correct files')
                return
            }
        } catch (error) {
            console.log("CATCH", eligibleFiles.length);
            // console.error('Error uploading files:', error)
            // if (eligibleFiles.length === 0) {
            //     setSoftwareUploadError(true);
            // };
            console.log("SOFTWARE CATCH ERROR", softwareUploadError);
            for (let i = 0; i < eligibleFiles.length; i++) {
                const eligibleFile = eligibleFiles[i];
                console.log("eligibleFile", eligibleFile);
                await uploadSoftwareFileToAWSFolder(eligibleFile, eligibleFile?.webkitRelativePath, eligibleFiles.length, i);
                if (TestVersion === 'Prod') {
                    setUpdateSystemVersion({ ...updateSystemVersion, updateSoftwareVersion: true });
                    if (eligibleFile.name.split('_')[0] === 'BE') {
                        const versionNumber = eligibleFile.name.split("_")[1].substring(0, eligibleFile.name.split("_")[1].lastIndexOf("."));
                        console.log("This is the changed file versionNumber", versionNumber);
                        setLastSystemVersionDetails((prevState) => ({
                            ...prevState,
                            hmiBackendVersion: versionNumber
                        }));
                    } else if (eligibleFile.name.split('_')[0] === 'UI') {
                        const versionNumber = eligibleFile.name.split("_")[1].substring(0, eligibleFile.name.split("_")[1].lastIndexOf("."));
                        console.log(`THis is the ${versionNumber}`);
                        setLastSystemVersionDetails((prevState) => ({
                            ...prevState,
                            hmiUiVersion: versionNumber
                        }))
                    }
                }
                else if(TestVersion==='Test'){
                    setUpdateSystemVersionTest(true);
                    if (eligibleFile.name.split('_')[0] === 'BE') {
                        const versionNumber = eligibleFile.name.split("_")[1].substring(0, eligibleFile.name.split("_")[1].lastIndexOf("."));
                        console.log("This is the changed file versionNumber", versionNumber);
                        setLastSystemVersionDetailsTest((prevState) => ({
                            ...prevState,
                            hmiBackendVersion: versionNumber
                        }));
                    } else if (eligibleFile.name.split('_')[0] === 'UI') {
                        const versionNumber = eligibleFile.name.split("_")[1].substring(0, eligibleFile.name.split("_")[1].lastIndexOf("."));
                        console.log(`THis is the ${versionNumber}`);
                        setLastSystemVersionDetailsTest((prevState) => ({
                            ...prevState,
                            hmiUiVersion: versionNumber
                        }))
                    }


                }
            }
        }
    };

    useEffect(() => {
        console.log("INSIDE SOFTWARE USEeFFECT ");
        if (selectedSoftwareFiles?.length) {
            uploadSelectedSoftwareFiles();
        }
    }, [selectedSoftwareFiles]);

    //FIRMWARE
    const uploadSelectedFirmwareFiles = async () => {
        if (selectedFirmwareFiles.length < FIRMWARE_FILES['MIN_NUM_FILES_REQUIRED']) {
            alert(`Minimum number of files required is ${FIRMWARE_FILES['MIN_NUM_FILES_REQUIRED']}`);
            return;
        }
        const sortedFiles = sortFileArray(selectedFirmwareFiles);
        let eligibleFiles = [];
        let masterFileEligibleForUpload = false;
        let slaveFileEligibleForUpload = false;
        try {
            for (let i = 0; i < sortedFiles.length; i++) {
                const selectedFirmwareFile = sortedFiles[i];
                if (selectedFirmwareFile.name.split('_')[0] === 'Master') {
                    
                    
                    const { fMasterVersion: fMasterVersionProd  } = lastSystemVersionDetails;
                    console.log(fMasterVersionProd);
                    const { fMasterVersion : fMasterVersionTest } = lastSystemVersionDetailsTest;
                    console.log(fMasterVersionTest);

                    if(TestVersion ==='Prod') {
                        masterFileEligibleForUpload = await shouldUploadFile(selectedFirmwareFile, fMasterVersionProd)
                    }
                    else if(TestVersion==='Test'){masterFileEligibleForUpload = await shouldUploadFileTest(selectedFirmwareFile, fMasterVersionProd , fMasterVersionTest);}



                    eligibleFiles.push(selectedFirmwareFile);

                } else if (selectedFirmwareFile.name.split('_')[0] === 'Slave') {
                    
                    const { fSlaveVersion: fSlaveVersionProd  } = lastSystemVersionDetails;
                    const { fSlaveVersion : fSlaveVersionTest } = lastSystemVersionDetailsTest;


                    

                    if(TestVersion==='Prod'){slaveFileEligibleForUpload = await shouldUploadFile(selectedFirmwareFile, fSlaveVersionProd);}
                    else if(TestVersion==='Test'){slaveFileEligibleForUpload = await shouldUploadFileTest(selectedFirmwareFile, fSlaveVersionProd , fSlaveVersionTest);}



                    eligibleFiles.push(selectedFirmwareFile);
                }
            }
            console.log(eligibleFiles);
            if (eligibleFiles?.length >= 2 && (masterFileEligibleForUpload || slaveFileEligibleForUpload)) {
                for (let i = 0; i < eligibleFiles.length; i++) {
                    const eligibleFile = eligibleFiles[i];
                    await uploadFirmwareFileToAWSFolder(eligibleFile, eligibleFile?.webkitRelativePath, eligibleFiles.length, i);

                    if(TestVersion==='Prod'){
                    setUpdateSystemVersion({ ...updateSystemVersion, updateFirmwareVersion: true });
                    if (eligibleFile.name.split('_')[0] === 'Master') {
                        const versionNumber = eligibleFile.name.split("_")[1].substring(0, eligibleFile.name.split("_")[1].lastIndexOf("."));
                        setLastSystemVersionDetails(Object.assign({}, lastSystemVersionDetails, { fMasterVersion: versionNumber }));
                        setLastSystemVersionDetails((prevState) => ({
                            ...prevState,
                            fMasterVersion: versionNumber
                        }));
                    } else if (eligibleFile.name.split('_')[0] === 'Slave') {
                        const versionNumber = eligibleFile.name.split("_")[1].substring(0, eligibleFile.name.split("_")[1].lastIndexOf("."));
                        setLastSystemVersionDetails((prevState) => ({
                            ...prevState,
                            fSlaveVersion: versionNumber
                        }))
                    }
                }
                else if(TestVersion==='Test'){
                    setUpdateSystemVersionTest({ ...updateSystemVersionTest, updateSoftwareVersionTest: true});

                    if (eligibleFile.name.split('_')[0] === 'Master') {
                        const versionNumber = eligibleFile.name.split("_")[1].substring(0, eligibleFile.name.split("_")[1].lastIndexOf("."));
                        setLastSystemVersionDetailsTest(Object.assign({}, lastSystemVersionDetailsTest, { fMasterVersion: versionNumber }));
                        setLastSystemVersionDetailsTest((prevState) => ({
                            ...prevState,
                            fMasterVersion: versionNumber
                        }));
                    } else if (eligibleFile.name.split('_')[0] === 'Slave') {
                        const versionNumber = eligibleFile.name.split("_")[1].substring(0, eligibleFile.name.split("_")[1].lastIndexOf("."));
                        setLastSystemVersionDetailsTest((prevState) => ({
                            ...prevState,
                            fSlaveVersion: versionNumber
                        }))
                    }
                }
                }

            }
            else {
                alert('No eligible file to upload, Please try again with correct files.')
                return
            }
        } catch (error) {
            console.error('Error uploading files:', error);
            for (let i = 0; i < eligibleFiles.length; i++) {
                const eligibleFile = eligibleFiles[i];
                await uploadFirmwareFileToAWSFolder(eligibleFile, eligibleFile?.webkitRelativePath, eligibleFiles.length, i);

                if(TestVersion==='Prod'){
                setUpdateSystemVersion({ ...updateSystemVersion, updateFirmwareVersion: true });
                if (eligibleFile.name.split('_')[0] === 'Master') {
                    const versionNumber = eligibleFile.name.split("_")[1].substring(0, eligibleFile.name.split("_")[1].lastIndexOf("."));
                    setLastSystemVersionDetails(Object.assign({}, lastSystemVersionDetails, { fMasterVersion: versionNumber }));
                    setLastSystemVersionDetails((prevState) => ({
                        ...prevState,
                        fMasterVersion: versionNumber
                    }));
                } else if (eligibleFile.name.split('_')[0] === 'Slave') {
                    const versionNumber = eligibleFile.name.split("_")[1].substring(0, eligibleFile.name.split("_")[1].lastIndexOf("."));
                    setLastSystemVersionDetails((prevState) => ({
                        ...prevState,
                        fSlaveVersion: versionNumber
                    }))
                }
            }
            else if(TestVersion==='Test'){
                setUpdateSystemVersionTest(true);
                if (eligibleFile.name.split('_')[0] === 'Master') {
                    const versionNumber = eligibleFile.name.split("_")[1].substring(0, eligibleFile.name.split("_")[1].lastIndexOf("."));
                    setLastSystemVersionDetailsTest(Object.assign({}, lastSystemVersionDetailsTest, { fMasterVersion: versionNumber }));
                    setLastSystemVersionDetailsTest((prevState) => ({
                        ...prevState,
                        fMasterVersion: versionNumber
                    }));
                } else if (eligibleFile.name.split('_')[0] === 'Slave') {
                    const versionNumber = eligibleFile.name.split("_")[1].substring(0, eligibleFile.name.split("_")[1].lastIndexOf("."));
                    setLastSystemVersionDetailsTest((prevState) => ({
                        ...prevState,
                        fSlaveVersion: versionNumber
                    }))
                }
            }
            }
            
        }
    };

    useEffect(() => {
        if (selectedFirmwareFiles?.length) {
            uploadSelectedFirmwareFiles();
        }
    }, [selectedFirmwareFiles]);

    //STMOS
    const uploadSelectedStmosFiles = async () => {
        if (selectedStmosFiles.length < STMOS_FILES['MIN_NUM_FILES_REQUIRED']) {
            alert(`Minimum number of files required is ${STMOS_FILES['MIN_NUM_FILES_REQUIRED']}`);
            return;
        }
        const sortedFiles = sortFileArray(selectedStmosFiles);
        let eligibleFiles = [];
        let OSFileAvailable = false;
        let InstallFileAvailable = false;
        let PyFileAvailable = false;
        let CappFileAvailable = false;
        try {
            for (let i = 0; i < sortedFiles.length; i++) {
                const selectedStmosFile = sortedFiles[i];
                if (selectedStmosFile.name.split('_')[0] === 'CAPP') {
                    const { stmCAppVersion : stmCAppVersionProd} = lastSystemVersionDetails;
                    const { stmCAppVersion : stmCAppVersionTest} = lastSystemVersionDetailsTest;
                    
                    if(TestVersion ==='Prod') {
                        CappFileAvailable = await shouldUploadFile(selectedStmosFile, stmCAppVersionProd);
                    }
                    else if(TestVersion==='Test'){
                        CappFileAvailable = await shouldUploadFileTest(selectedStmosFile, stmCAppVersionProd , stmCAppVersionTest);

                    }
                    eligibleFiles.push(selectedStmosFile);

                } else if (selectedStmosFile.name.split('_')[0] === 'OS') {
                    const { stmOsVersion : stmOsVersionProd} = lastSystemVersionDetails;
                    const { stmOsVersion : stmOsVersionTest} = lastSystemVersionDetailsTest;
                    
                    if(TestVersion ==='Prod') {
                        OSFileAvailable = await shouldUploadFile(selectedStmosFile, stmOsVersionProd);

                    }
                    else if(TestVersion==='Test'){
                        OSFileAvailable = await shouldUploadFileTest(selectedStmosFile, stmOsVersionProd , stmOsVersionTest);

                    }
                    eligibleFiles.push(selectedStmosFile);
                } else if (selectedStmosFile.name.split('_')[0] === 'PY') {

                    const { stmPyAppVersion : stmPyAppVersionProd} = lastSystemVersionDetails;
                    const { stmPyAppVersion : stmPyAppVersionTest} = lastSystemVersionDetailsTest;

                    if(TestVersion ==='Prod') {
                        PyFileAvailable = await shouldUploadFile(selectedStmosFile, stmPyAppVersionProd);

                    }
                    else if(TestVersion==='Test'){
                        PyFileAvailable = await shouldUploadFileTest(selectedStmosFile, stmPyAppVersionProd , stmPyAppVersionTest);

                    }
                    eligibleFiles.push(selectedStmosFile);
                } else if (selectedStmosFile.name.split('_')[0] === 'Install') {

                    const { stmOsVersion : stmOsVersionProd} = lastSystemVersionDetails;
                    const { stmOsVersion : stmOsVersionTest } = lastSystemVersionDetailsTest;

                    //checking for OS version as Install and OS version always remains same
                    if(TestVersion ==='Prod'){
                        InstallFileAvailable = await shouldUploadFile(selectedStmosFile, stmOsVersionProd);

                    }
                    else if(TestVersion==='Test'){
                        InstallFileAvailable = await shouldUploadFileTest(selectedStmosFile, stmOsVersionProd , stmOsVersionTest);

                    }
                    eligibleFiles.push(selectedStmosFile);
                }
            }

            if (!InstallFileAvailable) {
                alert("Install file is missing.");
                return;
            }
            

            // if (!(OSFileAvailable || PyFileAvailable) ) {
            //     alert("OS or PY file is missing.");
            //     return;
            // }


            if (eligibleFiles?.length) {
                for (let i = 0; i < eligibleFiles.length; i++) {
                    const eligibleFile = eligibleFiles[i];
                    await uploadStmosFileToAWSFolder(eligibleFile, eligibleFile?.webkitRelativePath, eligibleFiles.length, i);
                    if(TestVersion==='Prod'){
                        setUpdateSystemVersion({ ...updateSystemVersion, updateStmosVersion: true });
                        if (eligibleFile.name.split('_')[0] === 'CAPP') {
                            const versionNumber = eligibleFile.name.split("_")[1].substring(0, eligibleFile.name.split("_")[1].lastIndexOf("."));
                            console.log("This is the changed file versionNumber", versionNumber);
                            setLastSystemVersionDetails((prevState) => ({
                                ...prevState,
                                stmCAppVersion: versionNumber,
                            }));
                        } else if (eligibleFile.name.split('_')[0] === 'OS') {
                            const versionNumber = eligibleFile.name.split("_")[1].substring(0, eligibleFile.name.split("_")[1].lastIndexOf("."));
                            console.log(`This is the ${eligibleFile.name}`);
                            setLastSystemVersionDetails((prevState) => ({
                                ...prevState,
                                stmOsVersion: versionNumber,
                            }));
                        } else if (eligibleFile.name.split('_')[0] === 'PY') {
                            const versionNumber = eligibleFile.name.split("_")[1].substring(0, eligibleFile.name.split("_")[1].lastIndexOf("."));
                            console.log(`THis is the ${eligibleFile.name}`);
                            setLastSystemVersionDetails((prevState) => ({
                                ...prevState,
                                stmPyAppVersion: versionNumber,
                            }));
                        }
                    }
                    else if(TestVersion==='Test'){
                        setUpdateSystemVersionTest({ ...updateSystemVersionTest, updateOsVersionTest: true});
                        
                        if (eligibleFile.name.split('_')[0] === 'CAPP') {
                            const versionNumber = eligibleFile.name.split("_")[1].substring(0, eligibleFile.name.split("_")[1].lastIndexOf("."));
                            console.log("This is the changed file versionNumber", versionNumber);
                            setLastSystemVersionDetailsTest((prevState) => ({
                                ...prevState,
                                stmCAppVersion: versionNumber,
                            }));
                        } else if (eligibleFile.name.split('_')[0] === 'OS') {
                            const versionNumber = eligibleFile.name.split("_")[1].substring(0, eligibleFile.name.split("_")[1].lastIndexOf("."));
                            console.log(`This is the ${eligibleFile.name}`);
                            setLastSystemVersionDetailsTest((prevState) => ({
                                ...prevState,
                                stmOsVersion: versionNumber,
                            }));
                        } else if (eligibleFile.name.split('_')[0] === 'PY') {
                            const versionNumber = eligibleFile.name.split("_")[1].substring(0, eligibleFile.name.split("_")[1].lastIndexOf("."));
                            console.log(`THis is the ${eligibleFile.name}`);
                            setLastSystemVersionDetailsTest((prevState) => ({
                                ...prevState,
                                stmPyAppVersion: versionNumber,
                            }));
                        }
                    }
                    
                }
            }
            else {
                alert('No eligible file to upload, Please try again with correct files.')
                return
            }
        } catch (error) {
            console.log(`Error uploading files:`, error);
            console.log(eligibleFiles.length);

            for (let i = 0; i < eligibleFiles.length; i++) {
                const eligibleFile = eligibleFiles[i];
                await uploadStmosFileToAWSFolder(eligibleFile, eligibleFile?.webkitRelativePath, eligibleFiles.length, i);
                if(TestVersion==='Prod'){
                    setUpdateSystemVersion({ ...updateSystemVersion, updateStmosVersion: true });
                    if (eligibleFile.name.split('_')[0] === 'CAPP') {
                        const versionNumber = eligibleFile.name.split("_")[1].substring(0, eligibleFile.name.split("_")[1].lastIndexOf("."));
                        console.log("This is the changed file versionNumber", versionNumber);
                        setLastSystemVersionDetails((prevState) => ({
                            ...prevState,
                            stmCAppVersion: versionNumber,
                        }));
                    } else if (eligibleFile.name.split('_')[0] === 'OS') {
                        const versionNumber = eligibleFile.name.split("_")[1].substring(0, eligibleFile.name.split("_")[1].lastIndexOf("."));
                        console.log(`This is the ${eligibleFile.name}`);
                        setLastSystemVersionDetails((prevState) => ({
                            ...prevState,
                            stmOsVersion: versionNumber,
                        }));
                    } else if (eligibleFile.name.split('_')[0] === 'PY') {
                        const versionNumber = eligibleFile.name.split("_")[1].substring(0, eligibleFile.name.split("_")[1].lastIndexOf("."));
                        console.log(`THis is the ${eligibleFile.name}`);
                        setLastSystemVersionDetails((prevState) => ({
                            ...prevState,
                            stmPyAppVersion: versionNumber,
                        }));
                    }
                }
                else if(TestVersion==='Test'){
                    setUpdateSystemVersionTest({ ...updateSystemVersionTest, updateOsVersionTest: true});
                    
                    if (eligibleFile.name.split('_')[0] === 'CAPP') {
                        const versionNumber = eligibleFile.name.split("_")[1].substring(0, eligibleFile.name.split("_")[1].lastIndexOf("."));
                        console.log("This is the changed file versionNumber", versionNumber);
                        setLastSystemVersionDetailsTest((prevState) => ({
                            ...prevState,
                            stmCAppVersion: versionNumber,
                        }));
                    } else if (eligibleFile.name.split('_')[0] === 'OS') {
                        const versionNumber = eligibleFile.name.split("_")[1].substring(0, eligibleFile.name.split("_")[1].lastIndexOf("."));
                        console.log(`This is the ${eligibleFile.name}`);
                        setLastSystemVersionDetailsTest((prevState) => ({
                            ...prevState,
                            stmOsVersion: versionNumber,
                        }));
                    } else if (eligibleFile.name.split('_')[0] === 'PY') {
                        const versionNumber = eligibleFile.name.split("_")[1].substring(0, eligibleFile.name.split("_")[1].lastIndexOf("."));
                        console.log(`THis is the ${eligibleFile.name}`);
                        setLastSystemVersionDetailsTest((prevState) => ({
                            ...prevState,
                            stmPyAppVersion: versionNumber,
                        }));
                    }
                }
                
            }
            
        }
    };

    useEffect(() => {
        if (selectedStmosFiles?.length) {
            uploadSelectedStmosFiles();
        }
    }, [selectedStmosFiles]);


    //IOMT
    const uploadSelectedIomtFiles = async () => {
        if (selectedIomtFiles.length < IOMT_FILES['MIN_NUM_FILES_REQUIRED']) {
            alert(`Minimum number of files required is ${IOMT_FILES['MIN_NUM_FILES_REQUIRED']}`);
            return;
        }
        const sortedFiles = sortFileArray(selectedIomtFiles);
        let eligibleFiles = [];
        let OSFileAvailable = false;
        let InstallFileAvailable = false;
        let PyFileAvailable = false;
        let JappFileAvailable = false;
        try {
            for (let i = 0; i < sortedFiles.length; i++) {
                const selectedIomtFile = sortedFiles[i];
                if (selectedIomtFile.name.split('_')[0] === 'JAPP') {
                    const { iomtJAppVersion :  iomtJAppVersionProd} = lastSystemVersionDetails;
                    const { iomtJAppVersion : iomtJAppVersionTest} = lastSystemVersionDetailsTest;
                    
                    if(TestVersion==='Prod'){
                        JappFileAvailable = await shouldUploadFile(selectedIomtFile, iomtJAppVersionProd);

                    }
                    else if(TestVersion==='Test'){
                        JappFileAvailable = await shouldUploadFileTest(selectedIomtFile, iomtJAppVersionProd , iomtJAppVersionTest);

                    }
                    eligibleFiles.push(selectedIomtFile);
                } else if (selectedIomtFile.name.split('_')[0] === 'OS') {
                    const { iomtOsVersion : iomtOsVersionProd } = lastSystemVersionDetails;
                    const { iomtOsVersion  : iomtOsVersionTest} = lastSystemVersionDetailsTest;
                    
                    if(TestVersion==='Prod'){
                        OSFileAvailable = await shouldUploadFile(selectedIomtFile, iomtOsVersionProd);

                    }
                    else if(TestVersion==='Test'){
                        OSFileAvailable = await shouldUploadFileTest(selectedIomtFile, iomtOsVersionProd , iomtOsVersionTest);

                    }
                    eligibleFiles.push(selectedIomtFile);
                } else if (selectedIomtFile.name.split('_')[0] === 'PY') {
                    const { iomtPyAppVersion  : iomtPyAppVersionProd} = lastSystemVersionDetails;
                    const { iomtPyAppVersion  : iomtPyAppVersionTest} = lastSystemVersionDetailsTest;
                    
                    if(TestVersion==='Prod'){
                        PyFileAvailable = await shouldUploadFile(selectedIomtFile, iomtPyAppVersionProd);

                    }
                    else if(TestVersion==='Test'){
                        PyFileAvailable = await shouldUploadFileTest(selectedIomtFile, iomtPyAppVersionProd , iomtPyAppVersionTest);

                    }
                    eligibleFiles.push(selectedIomtFile);
                } else if (selectedIomtFile.name.split('_')[0] === 'Install') {
                    InstallFileAvailable = true;
                    const { iomtOsVersion  : iomtOsVersionProd} = lastSystemVersionDetails;
                    const { iomtOsVersion  : iomtOsVersionTest} = lastSystemVersionDetailsTest;

                    //checking for OS version as Install and OS version always remains same
                    if(TestVersion==='Prod'){
                        InstallFileAvailable = await shouldUploadFile(selectedIomtFile, iomtOsVersionProd);

                    }
                    else if(TestVersion==='Test'){
                        InstallFileAvailable = await shouldUploadFileTest(selectedIomtFile, iomtOsVersionProd , iomtOsVersionTest);

                    }
                    eligibleFiles.push(selectedIomtFile);
                }
            }
            // if (!(OSFileAvailable || PyFileAvailable)) {
            //     alert("OS or PY file is missing.");
            //     return;
            // }
            console.log("=== InstallFileAvailable > ", InstallFileAvailable, 'OSFileAvailable > ', OSFileAvailable, 'JappFileAvailable > ', JappFileAvailable, 'PyFileAvailable > ', PyFileAvailable, "===")
            if (!(InstallFileAvailable || OSFileAvailable || JappFileAvailable || PyFileAvailable)) {
                alert("Files missing.");
                return;
            }
            if (eligibleFiles?.length) {
                for (let i = 0; i < eligibleFiles.length; i++) {
                    const eligibleFile = eligibleFiles[i];
                    console.log(eligibleFile.name, eligibleFile);
                    await uploadIomtFileToAWSFolder(eligibleFile, eligibleFile?.webkitRelativePath, eligibleFiles.length, i);
                    if(TestVersion==='Prod'){
                        setUpdateSystemVersion({ ...updateSystemVersion, updateIomtVersion: true });
                        if (eligibleFile.name.split('_')[0] === 'JAPP') {
                            const versionNumber = eligibleFile.name.split("_")[1].substring(0, eligibleFile.name.split("_")[1].lastIndexOf("."));
                            console.log("This is the changed file versionNumber", versionNumber);
                            setLastSystemVersionDetails((prevState) => ({
                                ...prevState,
                                iomtJAppVersion: versionNumber,
                            }));
                        } else if (eligibleFile.name.split('_')[0] === 'OS') {
                            const versionNumber = eligibleFile.name.split("_")[1].substring(0, eligibleFile.name.split("_")[1].lastIndexOf("."));
                            console.log(`THis is the ${eligibleFile.name}`);
                            setLastSystemVersionDetails((prevState) => ({
                                ...prevState,
                                iomtOsVersion: versionNumber,
                            }));
                        } else if (eligibleFile.name.split('_')[0] === 'PY') {
                            const versionNumber = eligibleFile.name.split("_")[1].substring(0, eligibleFile.name.split("_")[1].lastIndexOf("."));
                            console.log(`THis is the ${eligibleFile.name}`);
                            setLastSystemVersionDetails((prevState) => ({
                                ...prevState,
                                iomtPyAppVersion: versionNumber,
                            }));
                        }
                    }
                    else if(TestVersion==='Test'){
                        setUpdateSystemVersionTest({ ...updateSystemVersionTest, updateOsVersionTest: true});

                        if (eligibleFile.name.split('_')[0] === 'JAPP') {
                            const versionNumber = eligibleFile.name.split("_")[1].substring(0, eligibleFile.name.split("_")[1].lastIndexOf("."));
                            console.log("This is the changed file versionNumber", versionNumber);
                            setLastSystemVersionDetailsTest((prevState) => ({
                                ...prevState,
                                iomtJAppVersion: versionNumber,
                            }));
                        } else if (eligibleFile.name.split('_')[0] === 'OS') {
                            const versionNumber = eligibleFile.name.split("_")[1].substring(0, eligibleFile.name.split("_")[1].lastIndexOf("."));
                            console.log(`THis is the ${eligibleFile.name}`);
                            setLastSystemVersionDetailsTest((prevState) => ({
                                ...prevState,
                                iomtOsVersion: versionNumber,
                            }));
                        } else if (eligibleFile.name.split('_')[0] === 'PY') {
                            const versionNumber = eligibleFile.name.split("_")[1].substring(0, eligibleFile.name.split("_")[1].lastIndexOf("."));
                            console.log(`THis is the ${eligibleFile.name}`);
                            setLastSystemVersionDetailsTest((prevState) => ({
                                ...prevState,
                                iomtPyAppVersion: versionNumber,
                            }));
                        }
                    }
                    
                }
            }
            else {
                alert('No eligible file to upload, Please try again with correct files.')
                return
            }
        } catch (error) {

            console.log(`Error uploading files:`, error);
            console.log(eligibleFiles.length);

            for (let i = 0; i < eligibleFiles.length; i++) {
                const eligibleFile = eligibleFiles[i];
                console.log(eligibleFile.name, eligibleFile);
                await uploadIomtFileToAWSFolder(eligibleFile, eligibleFile?.webkitRelativePath, eligibleFiles.length, i);
                if(TestVersion==='Prod'){
                    setUpdateSystemVersion({ ...updateSystemVersion, updateIomtVersion: true });
                    if (eligibleFile.name.split('_')[0] === 'JAPP') {
                        const versionNumber = eligibleFile.name.split("_")[1].substring(0, eligibleFile.name.split("_")[1].lastIndexOf("."));
                        console.log("This is the changed file versionNumber", versionNumber);
                        setLastSystemVersionDetails((prevState) => ({
                            ...prevState,
                            iomtJAppVersion: versionNumber,
                        }));
                    } else if (eligibleFile.name.split('_')[0] === 'OS') {
                        const versionNumber = eligibleFile.name.split("_")[1].substring(0, eligibleFile.name.split("_")[1].lastIndexOf("."));
                        console.log(`THis is the ${eligibleFile.name}`);
                        setLastSystemVersionDetails((prevState) => ({
                            ...prevState,
                            iomtOsVersion: versionNumber,
                        }));
                    } else if (eligibleFile.name.split('_')[0] === 'PY') {
                        const versionNumber = eligibleFile.name.split("_")[1].substring(0, eligibleFile.name.split("_")[1].lastIndexOf("."));
                        console.log(`THis is the ${eligibleFile.name}`);
                        setLastSystemVersionDetails((prevState) => ({
                            ...prevState,
                            iomtPyAppVersion: versionNumber,
                        }));
                    }
                }
                else if(TestVersion==='Test'){
                    setUpdateSystemVersionTest({ ...updateSystemVersionTest, updateOsVersionTest: true});

                    if (eligibleFile.name.split('_')[0] === 'JAPP') {
                        const versionNumber = eligibleFile.name.split("_")[1].substring(0, eligibleFile.name.split("_")[1].lastIndexOf("."));
                        console.log("This is the changed file versionNumber", versionNumber);
                        setLastSystemVersionDetailsTest((prevState) => ({
                            ...prevState,
                            iomtJAppVersion: versionNumber,
                        }));
                    } else if (eligibleFile.name.split('_')[0] === 'OS') {
                        const versionNumber = eligibleFile.name.split("_")[1].substring(0, eligibleFile.name.split("_")[1].lastIndexOf("."));
                        console.log(`THis is the ${eligibleFile.name}`);
                        setLastSystemVersionDetailsTest((prevState) => ({
                            ...prevState,
                            iomtOsVersion: versionNumber,
                        }));
                    } else if (eligibleFile.name.split('_')[0] === 'PY') {
                        const versionNumber = eligibleFile.name.split("_")[1].substring(0, eligibleFile.name.split("_")[1].lastIndexOf("."));
                        console.log(`THis is the ${eligibleFile.name}`);
                        setLastSystemVersionDetailsTest((prevState) => ({
                            ...prevState,
                            iomtPyAppVersion: versionNumber,
                        }));
                    }
                }
                
            }
        }
    }

    useEffect(() => {
        if (selectedIomtFiles?.length) {
            uploadSelectedIomtFiles();
        }
    }, [selectedIomtFiles]);


    const handleButtonClick = () => {
        if (softwareFileInputRef.current !== null) {
            softwareFileInputRef.current.click();
        }
        if (iomtFileInputRef.current !== null) {
            iomtFileInputRef.current.click();
        }
        if (firmwareFileInputRef.current !== null) {
            firmwareFileInputRef.current.click();
        }

        if (stmosFileInputRef.current !== null) {
            stmosFileInputRef.current.click();
        }

    };

    useEffect(() => {
        if (selectedSoftwareFiles.length > 0) {
            handleUploadProgress('software');
        }
        if (selectedFirmwareFiles.length > 0) {
            handleUploadProgress('firmware');
        }
        if (selectedStmosFiles.length > 0) {
            handleUploadProgress('stmos');
        }
        if (selectedIomtFiles.length > 0) {
            handleUploadProgress('iomt');
        }

        return () => {

        }
    }, [selectedSoftwareFiles, selectedFirmwareFiles, selectedIomtFiles, selectedStmosFiles]);

    const handleCloseButton = () => {
        props.closeModal();
    };

    const handleUploadProgress = (fileCategory) => {
        switch (fileCategory) {
            case 'software':
                if (selectedSoftwareFiles.length > 0 && softwareVersionUploadProgress === false) {
                    setSoftwareVersionUploadProgress((prev) => !prev);
                }
                break;
            case 'firmware':
                if (selectedFirmwareFiles.length > 0 && firmwareVersionUploadProgress === false) {
                    setFirmwareVersionUploadProgress((prev) => !prev);
                }
                break;
            case 'stmos':
                if (selectedStmosFiles.length > 0 && stmosVersionUploadProgress === false) {
                    setStmosVersionUploadProgress((prev) => !prev);
                }
                break;
            case 'iomt':
                if (selectedIomtFiles.length > 0 && iomtVersionUploadProgress === false) {
                    setIomtVersionUploadProgress((prev) => !prev);
                }
                break;
            default:
                break;
        };
    };


    const handleVersionProgressCrossIcon = (e, versionType) => {
        switch (versionType) {
            case 'software':
                // console.log("Software Cross Icon detected");
                setSoftwareVersionUploadProgress((prev) => !prev);
                break;
            case 'firmware':
                // console.log("Firmware Cross Icon detected");
                setFirmwareVersionUploadProgress((prev) => !prev);
                break;
            case 'stmos':
                // console.log("STMOS Cross Icon detected");
                setStmosVersionUploadProgress((prev) => !prev);
                break;
            case 'iomt':
                // console.log("IOMT Cross Icon detected");
                setIomtVersionUploadProgress((prev) => !prev);
                break;

            default:
                break;
        }
    };

    const handleTryAgain = () => {
        console.log(`Try Again clicked!`);
        // setSoftwareVersionUploadProgress(false);
        // setUploadError(false);
        // setSelectedSoftwareFiles([]);
    };

    // console.log("stmosUploadError.wrongFileUploadError>>", stmosUploadError.wrongFileUploadError);

    const [TestVersion, setTestVersion] = useState('Prod');

    const handleRadioChange = (e) => {
        setTestVersion(e.target.value);
        console.log(e.target.value , '=========' , TestVersion)

        if(e.target.value==='Prod'){
            setLastSystemVersionDetails((prevState) => ({
                ...prevState,
                TestEnv: e.target.value
            }));
        }
        else if (e.target.value === 'Test') {
            setLastSystemVersionDetailsTest((prevState) => ({
                ...prevState,
                // osVersion: lastSystemVersionDetails.osVersion,
                // stmPyAppVersion: lastSystemVersionDetails.stmPyAppVersion,
                // stmCAppVersion: lastSystemVersionDetails.stmCAppVersion,
                // stmOsVersion: lastSystemVersionDetails.stmOsVersion,
                // iomtPyAppVersion: lastSystemVersionDetails.iomtPyAppVersion,
                // iomtJAppVersion: lastSystemVersionDetails.iomtJAppVersion,
                // iomtOsVersion: lastSystemVersionDetails.iomtOsVersion,
                TestEnv: e.target.value
            }));
        }

    };

    // const resetUploadState = () => {
    //     setSelectedFiles([]);
    //     setFileCategory('');
    //     setSoftwareUploadError({ wrongFileUploadError: false });
    //     setSoftwareVersionUploadProgress(false);
    //     if (softwareFileInputRef.current) {
    //         softwareFileInputRef.current.value = ''; // Clear the file input
    //     }
    // };

    // useEffect(() => {
    //     // Reset states when TestVersion changes
    //     resetUploadState();
    // }, [TestVersion]);
    
    if(TestVersion==='Prod'){ 
        console.log(lastSystemVersionDetails , 'Just for timepass-Prod')
    }else if(TestVersion==='Test'){
        console.log(lastSystemVersionDetailsTest , 'Just for timepass-Test')

    }
   

    return (
        <div className='full_screen_modal_container'>
            <div className='full_screen_modal'>
                <form>
                    <div className='upload_version_header'>
                        <h3>Upload Update</h3>
                        <img src={cross_svg} onClick={handleCloseButton} />
                    </div>

                    <div className="radio-group">
                        <label className={`radio-label ${TestVersion === 'Prod' ? 'selected' : ''}`}>
                            <input
                                type="radio"
                                value="Prod"
                                checked={TestVersion === 'Prod'}
                                onChange={handleRadioChange}
                            />
                            Prod
                        </label>
                        <label className={`radio-label ${TestVersion === 'Test' ? 'selected' : ''}`}>
                            <input
                                type="radio"
                                value="Test"
                                checked={TestVersion === 'Test'}
                                onChange={handleRadioChange}
                            />
                            Test
                        </label>
                        {/* <label className={`radio-label ${TestVersion === 'Test_on_Field' ? 'selected' : ''}`}>
                            <input
                                type="radio"
                                value="Test_on_Field"
                                checked={TestVersion === 'Test_on_Field'}
                                onChange={handleRadioChange}
                            />
                            Test on Field
                        </label> */}
                    </div>

                    {(TestVersion === 'Prod' || TestVersion === 'Test' || TestVersion === 'Test_on_Field') && (
                        <div className={`upload_version_section ${softwareVersionUploadProgress ? '' : 'upload_version_section_padding'}`}>
                            {softwareVersionUploadProgress ? (
                                showSoftwareUploadProgress ? (
                                    <UploadingProgress
                                        handleTryAgain={handleTryAgain}
                                        uploadError={softwareUploadError}
                                        countProgress={softwareFileUploadPercentage}
                                        handleVersionProgressCrossIcon={(e) => handleVersionProgressCrossIcon(e, 'software')}
                                        uploadMB={softwareUploadMB}
                                        totalMB={softwareTotalMB}
                                    />
                                ) : (
                                    <UploadSystemVersionSuccessful files={selectedSoftwareFiles} />
                                )
                            ) : (
                                <>
                                    {softwareUploadError.wrongFileUploadError ? <label style={{ color: "red", fontWeight: "500" }}>Uploading the wrong file <span className='wrong_file_error'>: )</span></label> : <label>Software Version</label>}
                                    <div>
                                        <label className={`${softwareUploadError.wrongFileUploadError ? "version_upload_button version_upload_button_error" : "version_upload_button"}`}>
                                            <input
                                                type="file"
                                                style={{ display: 'none' }}
                                                ref={softwareFileInputRef}
                                                onChange={(e) => handleFileChange(e, 'software')}
                                                webkitdirectory=""
                                            />
                                            Upload
                                        </label>
                                    </div>
                                </>
                            )}
                        </div>
                    )}

                    {(TestVersion === 'Prod' || TestVersion === 'Test' || TestVersion === 'Test_on_Field') && (
                        <div className={`upload_version_section ${firmwareVersionUploadProgress ? '' : 'upload_version_section_padding'}`}>
                            {firmwareVersionUploadProgress ? (
                                showFirmwareUploadProgress ? (
                                    <UploadingProgress
                                        handleVersionProgressCrossIcon={(e) => handleVersionProgressCrossIcon(e, 'firmware')}
                                        handleTryAgain={handleTryAgain}
                                        uploadError={firmwareUploadError}
                                        countProgress={firmwareFileUploadPercentage}
                                        uploadMB={firmwareUploadMB}
                                        totalMB={firmwareTotalMB}
                                    />
                                ) : (
                                    <UploadSystemVersionSuccessful files={selectedFirmwareFiles} />
                                )
                            ) : (
                                <>
                                    {firmwareUploadError.wrongFileUploadError ? <label style={{ color: "red", fontWeight: "500" }}>Uploading the wrong file <span className='wrong_file_error'>: )</span></label> : <label>Firmware Version</label>}
                                    <div>
                                        <label className={`${firmwareUploadError.wrongFileUploadError ? "version_upload_button version_upload_button_error" : "version_upload_button"}`}>
                                            <input
                                                type="file"
                                                style={{ display: 'none' }}
                                                ref={firmwareFileInputRef}
                                                onChange={(e) => handleFileChange(e, 'firmware')}
                                                webkitdirectory=""
                                            />
                                            Upload
                                        </label>
                                    </div>
                                </>
                            )}
                        </div>


                    )}


                    {(TestVersion === 'Prod' || TestVersion === 'Test' || TestVersion === 'Test_on_Field') && (
                        <div className={`upload_version_section ${stmosVersionUploadProgress ? '' : 'upload_version_section_padding'}`}>
                            {stmosVersionUploadProgress ? (
                                showStmosUploadProgress ? (
                                    <UploadingProgress
                                        handleVersionProgressCrossIcon={(e) => handleVersionProgressCrossIcon(e, 'stmos')}
                                        handleTryAgain={handleTryAgain}
                                        uploadError={stmosUploadError}
                                        countProgress={stmosFileUploadPercentage}
                                        uploadMB={stmosUploadMB}
                                        totalMB={stmosTotalMB}
                                    />
                                ) : (
                                    <UploadSystemVersionSuccessful files={selectedStmosFiles} />
                                )
                            ) : (
                                <>
                                    {stmosUploadError.wrongFileUploadError ? <label style={{ color: "red", fontWeight: "500" }}>Uploading the wrong file <span className='wrong_file_error'>: )</span></label> : <label>STMOS Version</label>}
                                    {/* <label>STMOS Version</label> */}
                                    <div>
                                        <label className={`${stmosUploadError.wrongFileUploadError ? "version_upload_button version_upload_button_error" : "version_upload_button"}`}>
                                            <input
                                                type="file"
                                                style={{ display: 'none' }}
                                                ref={stmosFileInputRef}
                                                onChange={(e) => handleFileChange(e, 'stmos')}
                                                webkitdirectory=""
                                            />
                                            Upload
                                        </label>
                                    </div>
                                </>
                            )}
                        </div>

                    )}



                    {(TestVersion === 'Prod' || TestVersion === 'Test' || TestVersion === 'Test_on_Field') && (
                        <div className={`upload_version_section ${iomtVersionUploadProgress ? '' : 'upload_version_section_padding'}`}>
                            {iomtVersionUploadProgress ? (
                                showIomtUploadProgress ? (
                                    <UploadingProgress
                                        handleVersionProgressCrossIcon={(e) => handleVersionProgressCrossIcon(e, 'iomt')}
                                        handleTryAgain={handleTryAgain}
                                        uploadError={iomtUploadError}
                                        countProgress={iomtFileUploadPercentage}
                                        uploadMB={iomtUploadMB}
                                        totalMB={iomtTotalMB}
                                    />
                                ) : (
                                    <UploadSystemVersionSuccessful files={selectedIomtFiles} />
                                )
                            ) : (
                                <>
                                    {iomtUploadError.wrongFileUploadError ? <label style={{ color: "red", fontWeight: "500" }}>Uploading the wrong file <span className='wrong_file_error'>: )</span></label> : <label>IOMT Version</label>}
                                    {/* <label>IOMT Version</label> */}
                                    <div>
                                        <label className={`${iomtUploadError.wrongFileUploadError ? "version_upload_button version_upload_button_error" : "version_upload_button"}`}>
                                            <input
                                                type="file"
                                                style={{ display: 'none' }}
                                                ref={iomtFileInputRef}
                                                onChange={(e) => handleFileChange(e, 'iomt')}
                                                webkitdirectory=""
                                            />
                                            Upload
                                        </label>
                                    </div>
                                </>
                            )}
                        </div>


                    )}

                    {(TestVersion != null) && (
                        <>

                            <div className='system_version_details_container'>
                                {(TestVersion === 'Prod' || TestVersion === 'Test' || TestVersion === 'Test_on_Field') && (

                                    <div>
                                        <input
                                            type='text'
                                            placeholder='System Version '
                                            value={ TestVersion === 'Prod' ? latestSystemVersion.softwareVersion
                                                : latestSystemVersionTest.softwareVersion
                                               }

                                            onChange={(e) => (e)}
                                        />
                                        {lastSystemVersionDetails.softwareVersion ? <span>System Version</span> : ""}
                                    </div>
                                )}
                                {(TestVersion === 'Prod' || TestVersion === 'Test' || TestVersion === 'Test_on_Field') && (
                                    <div>
                                        <input
                                            type='text'
                                            placeholder='Os Version '
                                            value={ TestVersion === 'Prod' ? latestSystemVersion.osVersion
                                                : latestSystemVersionTest.osVersion
                                               }
                                            onChange={(e) => (e)}
                                        />
                                        {latestSystemVersion.osVersion ? <span>Os Version</span> : ""}
                                    </div>
                                )}

                            </div>

                            {(TestVersion !=null) && (
                                <>
                                <div className='version_details'>
                                    <textarea
                                        type='textarea'
                                        placeholder='Description'
                                        value={description}
                                        onChange={(e) => handleDescriptionChange(e)}
                                    />
                                </div>
                             {<span className={apiResponse.success === false ? "error_style" : "success_style"}>{apiResponse.message}</span>}
                            <div className='system_update_button'>
                                <button type="button" onClick={handleCloseButton}>Cancel</button>
                                <button
                                    type="button"
                                    onClick={(e) => handleSystemVersionUpload(e)}
                                    disabled={handleUploadButtonClass()}
                                    className={handleUploadButtonClass() ? "upload_button_disable" : ""}
                                >
                                    Upload
                                </button>
                            </div>

                            </>
                            )}

                        </>
                    )}

                </form>
            </div>
        </div>
    )
};

const mapStateToProps = state => {
    return {
        showModal: state.uploadSystemVersionFullScreenModal.showUploadSystemVersionModal,
        showNotification: state.showNotification.showNotificatin
    };
};

const mapDispatchToProps = dispatch => {
    return {
        openModal: () => { dispatch(setUploadSystemVersionFullScreenModal(true)) },
        closeModal: () => { dispatch(setUploadSystemVersionFullScreenModal(false)) },
        openNotification: () => { dispatch(setNotificationVisibility(true)) },
        closeNotification: () => { dispatch(setNotificationVisibility(false)) },
        showSystemVersionUploadNotification: () => { dispatch(showSystemVersionUploadNotification(true)) }

    }
};


export default connect(mapStateToProps, mapDispatchToProps)(UploadSystemVersionFullScreenModal);
import axios from 'axios';
import { getCookieCustom } from '../../common/utils/StorageUItils';
import { LOGIN_CONST } from '../../common/constant/page-constants';

export const uploadFile = async (file, folderName, TestVersion, onUploadProgress) => {
  const formData = new FormData();
  formData.append(`file`, file);
  
  console.log(folderName);
  console.log(TestVersion);

  try {
    const response = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_BASE_URL}/admindashboard/api/uploadVersionFile?folderName=${folderName}&TestVersion=${TestVersion}`,
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': getCookieCustom(LOGIN_CONST.ACCESS_TOKEN)
      },
      onUploadProgress: (progressEvent) => {
        const progress = {
          loaded: progressEvent.loaded,
          total: progressEvent.total
        };
        onUploadProgress(progress);
      },
    });
    console.log(response.data);
  } catch (error) {
    console.error('Error uploading files:', error);
  }
};

import React, { useEffect, useRef, useState } from 'react';
import './UpdateSystemFullScreenModal.css';
import { selectMachineToUpdateVersionButton, setNotificationVisibility, setSystemUpdateFullScreenModal, showUpdateMachineVersionNotification } from '../../modules/actions/action';
import { connect } from 'react-redux';
import { getSystemVersionDetails } from '../../helper/SystemVersionDetailsHelper';
import { postMachineVersionUpdate } from '../../helper/MachineVersionUpdateHelper';

const UpdateSystemFullScreenModal = (props) => {
    const [dataContainer, setDataContainer] = useState(null); // Initially set to null
    const [latestThreeVersion, setLatestThreeVersion] = useState([]); // All versions will be stored here
    const [selectedVersion, setSelectedVersion] = useState([]);
    const [newVersionData, setNewVersionData] = useState(null);
    const [disableButton, setDisableButton] = useState(true);
    const [TestVersion, setTestVersion] = useState('Prod');
    const ref = useRef(true);

    const handleModalVisibility = () => {
        props.closeModal();
        props.openNotification();
    };

    const handleModalClose = () => {
        props.closeModal();
    };

    const handleOnChange = (e) => {
        console.log(e.target.value);
        const version = JSON.parse(e.target.value);
        setSelectedVersion(version);
        console.log("Selected Version to send to the Machine: ", version.newSoftwareVersion, version.newOsVersion);
        console.log("Machine Selected Data: ", props.selectedMachineToUpdateVersion);
    };

    useEffect(() => {
        if (selectedVersion) {
            setNewVersionData(selectedVersion);
            setDisableButton(false);
            console.log("Selected Version", selectedVersion);
        }
    }, [selectedVersion]);

    const handleMachineUpdateVersion = () => {
        const dataArray = props.selectedMachineToUpdateVersion.map((machineUDI) => ({
            machineUDI,
            newSoftwareVersion: selectedVersion.newSoftwareVersion,
            newOSVersion: selectedVersion.newOsVersion
        }));
        console.log("Machine Version Update Data", dataArray);

        const machineData = { data: dataArray };
        const data = JSON.stringify(machineData);
        console.log("Machine Data: ", data);
        postMachineVersionUpdate(data)
            .then((res) => {
                console.log("Response: " + res);
                props.showMachineUpdateNotification();
            })
            .catch((err) => {
                console.log("ERROR RESPONSE", err.response?.data?.message || err.message);
            });
    };

    useEffect(() => {
        if (ref.current === true) {
            handleSystemVersionDataGet();
        }
        return () => {
            ref.current = false;
        };
    }, [ref]);

    const handleSystemVersionDataGet = () => {
        getSystemVersionDetails()
            .then((res) => {
                console.log("API Response:", res);
                let data = res.data;
                setDataContainer(data); // Set the dataContainer with the API response
            })
            .catch((err) => {
                console.log("API Error:", err);
            });
    };

    const getAllVersions = (versionType) => {
        if (dataContainer) {
            console.log("Data is running");
            const selectedData = versionType === 'Prod' ? dataContainer.prod : dataContainer.test; // Use versionType to select data
            console.log(selectedData);  // Log the selected data
            if (selectedData && selectedData.length > 0) {
                setLatestThreeVersion(selectedData); // Set the entire list instead of slicing
                console.log("All Versions:", selectedData);
            } else {
                console.log("No data available for the selected version.");
            }
        } else {
            console.log("Data is not available yet.");
        }
    };

    useEffect(() => {
        if (dataContainer) {
            getAllVersions(TestVersion); // Fetch all versions
        }
    }, [dataContainer, TestVersion]); // Trigger effect when dataContainer or TestVersion changes

    const handleRadioChange = (e) => {
        setTestVersion(e.target.value);
        console.log(e.target.value, '=========', TestVersion);
    };

    return (
        <div className='full_screen_modal_container'>
            <div className='full_screen_modal'>
                <form>
                    <p>Select System Version</p>
                    <div className="radio-group">
                        <label className={`radio-label ${TestVersion === 'Prod' ? 'selected' : ''}`}>
                            <input
                                type="radio"
                                value="Prod"
                                checked={TestVersion === 'Prod'}
                                onChange={handleRadioChange}
                            />
                            Prod
                        </label>
                        <label className={`radio-label ${TestVersion === 'Test' ? 'selected' : ''}`}>
                            <input
                                type="radio"
                                value="Test"
                                checked={TestVersion === 'Test'}
                                onChange={handleRadioChange}
                            />
                            Test
                        </label>
                    </div>

                    <div className="scrollable-container">
                        {latestThreeVersion.length > 0 ? (
                            latestThreeVersion.map((sysVersion, index) => {
                                const uniqueKey = `${sysVersion.softwareVersion}-${sysVersion.osVersion}-${index}`;
                                console.log("Generated Unique Key:", uniqueKey); // Log the unique key to the console
                                return (
                                    <div className='system_version_container' key={uniqueKey}>
                                        <div className='system_version_container_card'>
                                            <input
                                                type="radio"
                                                name="system_version"
                                                id="system_version_1"
                                                value={JSON.stringify({ newSoftwareVersion: sysVersion.softwareVersion, newOsVersion: sysVersion.osVersion })}
                                                onChange={(e) => handleOnChange(e)}
                                            />
                                            <div>
                                                <h3>Software Version {sysVersion.softwareVersion}</h3>
                                            </div>
                                            <div>
                                                <h3>Os Version {sysVersion.osVersion}</h3>
                                            </div>
                                        </div>
                                        <p>{sysVersion.description || 'No description available'}</p>
                                    </div>
                                );
                            })
                        ) : (
                            <p>Loading versions or no data available.</p>
                        )}
                    </div>

                    <div className='system_version_button'>
                        <button type="button" onClick={handleModalClose}>Cancel</button>
                        <button
                            type="button"
                            onClick={() => { handleModalVisibility(); handleMachineUpdateVersion() }}
                            disabled={disableButton}
                            style={disableButton ? { backgroundColor: "#6D1DF0", opacity: "0.2" } : {}}
                        >
                            Send
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        showModal: state.systemUpdateFullScreenModal.showSystemUpdateModal,
        showNotification: state.showNotification.showNotificatin,
        selectedMachineToUpdateVersion: state.selectMachineToUpdateVersion.selectedMachineToUpdateVersion
    };
};

const mapDispatchToProps = dispatch => {
    return {
        openModal: () => { dispatch(setSystemUpdateFullScreenModal(true)); },
        closeModal: () => { dispatch(setSystemUpdateFullScreenModal(false)); },
        openNotification: () => { dispatch(setNotificationVisibility(true)); },
        closeNotification: () => { dispatch(setNotificationVisibility(false)); },
        selectMachineToUpdateVersion: () => { dispatch(selectMachineToUpdateVersionButton()); },
        showMachineUpdateNotification: () => { dispatch(showUpdateMachineVersionNotification(true)); },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateSystemFullScreenModal);

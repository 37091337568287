// routes.js

import OtaDashboard from "../../pages/OtaDashboard/OtaDashboard";
import OtaDetails from "../../container/OtaDetails/OtaDetails";
import MachineDetails from "../../container/MachineDetails/MachineDetails";
import MachineVersionDetails from "../../container/MachineVersionDetails/MachineVersionDetails";
import OtaUpload from "../../container/OtaUpload/OtaUpload";
import SystemVersionDetails from "../../container/SystemVersionDetails/SystemVersionDetails";
import InactiveSystemVersionDetails from "../../container/InactiveSystemVersionDetails/InactiveSystemVersionDetails";
import IpLogs from "../../container/IpLogs/IpLogs";
import Login from "../../pages/Login/Login";
import RequestCallback from "../../container/RequestCallback/RequestCallback";
import DemoMachine from "../../container/DemoMachine/DemoMachine";
import { VentilatorAlarmTable } from "../../container/EventHistory/EventHistory";

const routes = [
    {
        key: "login",
        path: "/",
        exact: true,
        element: <Login />,
        children: [],
        authNeeded: false,
    },
    {
        key: "ota-dashboard",
        path: "/",
        exact: true,
        element: <OtaDashboard />,
        authNeeded: true,
        children: [
            {
                path: "ota-details",
                element: <OtaDetails />,
                exact: true,
                key: "ota-details",
                children: [
                    {
                        path: "machine-details",
                        element: <MachineDetails />,
                        exact: true,    
                        key: "machine-details",
                        authNeeded: true,
                    },
                    {
                        path: `machine-details/:machine_sn`,
                        element: <MachineVersionDetails />,
                        exact: true,
                        key: "machine-details/:machine_sn",
                        authNeeded: true,
                    },
                ],
            },
            {
                path: "ota-upload",
                element: <OtaUpload />,
                key: "ota-upload",
                children: [
                    {
                        path: "all-system-version-details",
                        element: <SystemVersionDetails />,
                        exact: true,
                        key: "all-system-version-details",
                        authNeeded: true,
                    },
                    // {
                    //     path: "inactive-system-version-details",
                    //     element: <InactiveSystemVersionDetails />,
                    //     exact: true,
                    //     key: "inactive-system-version-details",
                    //     authNeeded: true,
                    // }
                ],
            },
            {
                key: "ip-log",
                path: "/ip-log",
                exact: true,
                element: <IpLogs />,
                children: [],
                authNeeded: true,
            },
            {
                key: "ventilator-alarm",
                path: "/ventilator-alarm",
                exact: false,
                element: <VentilatorAlarmTable />,
                children: [],
                authNeeded: true,
            },
            {
                key: "request-callback",
                path: "/request-callback",
                exact: true,
                element: <RequestCallback />,
                children: [],
                authNeeded: true,
            },
            {
                key: "demo-machine",
                path: "/demo-machine",
                exact: true,
                element: <DemoMachine />,
                children: [],
                authNeeded: true,
            }
        ],
    },
];

export { routes };


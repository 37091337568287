import { SoftwareVersionUploadAPI } from "../../adapters/SystemVersionUploadAdapter/SoftwareVersionUploadAdapter";
export const saveSoftwareVersionUploadHelper = (versionData) => {
    console.log("Software Version UPload API ");

    return (new Promise((resolve, reject) => {
        SoftwareVersionUploadAPI(versionData)
        .then((res) => {
            console.log("RESPONSE FROM THE API", res);

            if (res && res.data && res.status === 200) {
                resolve({ message: "Now getting response", data: res });
            } else if (res && res.success === false && res.status === 500) {
                resolve({ message: "Error Occurred", data: res.data, status: res.status });
            } else if (res && res.data && res.data.success === false) {
                resolve(res.data);
            } else {
                resolve({ message: "Unexpected response format", data: res });
            }
        })
        .catch((err) => {
            console.log("Catch Error Occurred", err);
            reject(err);
        });
    }));
};